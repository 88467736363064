/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAnonymizedMedicalTest = /* GraphQL */ `
  query GetAnonymizedMedicalTest($id: ID!) {
    getAnonymizedMedicalTest(id: $id) {
      id
      status
      result
      resultDate
    }
  }
`;
export const getReport = /* GraphQL */ `
  query GetReport($id: ID!) {
    getReport(id: $id) {
      id
      path
      tenant
      group
      name
      url
      dateCreated
    }
  }
`;
export const listReports = /* GraphQL */ `
  query ListReports(
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        path
        tenant
        group
        name
        url
        dateCreated
      }
      nextToken
    }
  }
`;
export const getQuestionnaire = /* GraphQL */ `
  query GetQuestionnaire($id: ID!) {
    getQuestionnaire(id: $id) {
      id
      questions {
        id
        question
        answer
      }
      sensorData {
        measurements {
          name
          unitOfMeasure
          measurement
        }
        source
      }
      reporters
      owner
      tenant
      screeningDate
      updatedOn
      adminGroup
      score
      resultCode
      result
      qrcode
      fullAccessGroup
      facial
    }
  }
`;
export const listQuestionnaires = /* GraphQL */ `
  query ListQuestionnaires(
    $filter: ModelQuestionnaireFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionnaires(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        questions {
          id
          question
          answer
        }
        sensorData {
          measurements {
            name
            unitOfMeasure
            measurement
          }
          source
        }
        reporters
        owner
        tenant
        screeningDate
        updatedOn
        adminGroup
        score
        resultCode
        result
        qrcode
        fullAccessGroup
        facial
      }
      nextToken
    }
  }
`;
export const getAnalyticConfiguration = /* GraphQL */ `
  query GetAnalyticConfiguration($tenant: ID!) {
    getAnalyticConfiguration(tenant: $tenant) {
      tenant
      questionConfiguration {
        id
        weight
        weightTrigger
      }
      resultConfiguration {
        id
        threshold
        emailAddressesToAlert
      }
      adminGroup
      createdAt
      updatedAt
      tenantObj {
        name
        description
        prettyName
        url
        analyticConfiguration {
          tenant
          questionConfiguration {
            id
            weight
            weightTrigger
          }
          resultConfiguration {
            id
            threshold
            emailAddressesToAlert
          }
          adminGroup
          createdAt
          updatedAt
          tenantObj {
            name
            description
            prettyName
            url
            analyticConfiguration {
              tenant
              questionConfiguration {
                id
                weight
                weightTrigger
              }
              resultConfiguration {
                id
                threshold
                emailAddressesToAlert
              }
              adminGroup
              createdAt
              updatedAt
              tenantObj {
                name
                description
                prettyName
                url
                adminGroup
                allowedRoles
                tenantAdminGroup
                fullReadAccess
                resultMetricEmailList
                resultMetricEmailTime
                questionnaireValidityTimeInSeconds
                latitude
                longitude
                fieldgroupid
                vizsafeApiUrl
                createdAt
                updatedAt
              }
            }
            adminGroup
            allowedRoles
            tenantAdminGroup
            fullReadAccess
            resultMetricEmailList
            resultMetricEmailTime
            questionnaireValidityTimeInSeconds
            latitude
            longitude
            fieldgroupid
            vizsafeApiUrl
            createdAt
            updatedAt
            codeTranslations {
              items {
                tenant
                language
                disclaimer
                adminGroup
                createdAt
                updatedAt
              }
              nextToken
            }
            invites {
              id
              emailAddress
              tenantId
              tenantProvidedUserId
              tenantRole
              tenantAdminGroup
              verificationCode
              verificationExpiration
              validated
              username
              firstName
              lastName
              profileImage
              createdAt
              updatedAt
            }
          }
        }
        adminGroup
        allowedRoles
        tenantAdminGroup
        fullReadAccess
        resultMetricEmailList
        resultMetricEmailTime
        questionnaireValidityTimeInSeconds
        latitude
        longitude
        fieldgroupid
        vizsafeApiUrl
        createdAt
        updatedAt
        codeTranslations {
          items {
            tenant
            language
            questions {
              id
              question
            }
            disclaimer
            analyticResults {
              id
              alias
              answer
              proceed
            }
            adminGroup
            createdAt
            updatedAt
            tenantObj {
              name
              description
              prettyName
              url
              analyticConfiguration {
                tenant
                adminGroup
                createdAt
                updatedAt
              }
              adminGroup
              allowedRoles
              tenantAdminGroup
              fullReadAccess
              resultMetricEmailList
              resultMetricEmailTime
              questionnaireValidityTimeInSeconds
              latitude
              longitude
              fieldgroupid
              vizsafeApiUrl
              createdAt
              updatedAt
              codeTranslations {
                nextToken
              }
              invites {
                id
                emailAddress
                tenantId
                tenantProvidedUserId
                tenantRole
                tenantAdminGroup
                verificationCode
                verificationExpiration
                validated
                username
                firstName
                lastName
                profileImage
                createdAt
                updatedAt
              }
            }
          }
          nextToken
        }
        invites {
          id
          emailAddress
          tenantId
          tenantProvidedUserId
          tenantRole
          tenantAdminGroup
          verificationCode
          verificationExpiration
          validated
          username
          firstName
          lastName
          profileImage
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listAnalyticConfigurations = /* GraphQL */ `
  query ListAnalyticConfigurations(
    $tenant: ID
    $filter: ModelAnalyticConfigurationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAnalyticConfigurations(
      tenant: $tenant
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tenant
        questionConfiguration {
          id
          weight
          weightTrigger
        }
        resultConfiguration {
          id
          threshold
          emailAddressesToAlert
        }
        adminGroup
        createdAt
        updatedAt
        tenantObj {
          name
          description
          prettyName
          url
          analyticConfiguration {
            tenant
            questionConfiguration {
              id
              weight
              weightTrigger
            }
            resultConfiguration {
              id
              threshold
              emailAddressesToAlert
            }
            adminGroup
            createdAt
            updatedAt
            tenantObj {
              name
              description
              prettyName
              url
              analyticConfiguration {
                tenant
                adminGroup
                createdAt
                updatedAt
              }
              adminGroup
              allowedRoles
              tenantAdminGroup
              fullReadAccess
              resultMetricEmailList
              resultMetricEmailTime
              questionnaireValidityTimeInSeconds
              latitude
              longitude
              fieldgroupid
              vizsafeApiUrl
              createdAt
              updatedAt
              codeTranslations {
                nextToken
              }
              invites {
                id
                emailAddress
                tenantId
                tenantProvidedUserId
                tenantRole
                tenantAdminGroup
                verificationCode
                verificationExpiration
                validated
                username
                firstName
                lastName
                profileImage
                createdAt
                updatedAt
              }
            }
          }
          adminGroup
          allowedRoles
          tenantAdminGroup
          fullReadAccess
          resultMetricEmailList
          resultMetricEmailTime
          questionnaireValidityTimeInSeconds
          latitude
          longitude
          fieldgroupid
          vizsafeApiUrl
          createdAt
          updatedAt
          codeTranslations {
            items {
              tenant
              language
              questions {
                id
                question
              }
              disclaimer
              analyticResults {
                id
                alias
                answer
                proceed
              }
              adminGroup
              createdAt
              updatedAt
              tenantObj {
                name
                description
                prettyName
                url
                adminGroup
                allowedRoles
                tenantAdminGroup
                fullReadAccess
                resultMetricEmailList
                resultMetricEmailTime
                questionnaireValidityTimeInSeconds
                latitude
                longitude
                fieldgroupid
                vizsafeApiUrl
                createdAt
                updatedAt
              }
            }
            nextToken
          }
          invites {
            id
            emailAddress
            tenantId
            tenantProvidedUserId
            tenantRole
            tenantAdminGroup
            verificationCode
            verificationExpiration
            validated
            username
            firstName
            lastName
            profileImage
            createdAt
            updatedAt
          }
        }
      }
      nextToken
    }
  }
`;
export const searchQuestionnaires = /* GraphQL */ `
  query SearchQuestionnaires(
    $filter: SearchableQuestionnaireFilterInput
    $sort: SearchableQuestionnaireSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchQuestionnaires(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        questions {
          id
          question
          answer
        }
        sensorData {
          measurements {
            name
            unitOfMeasure
            measurement
          }
          source
        }
        reporters
        owner
        tenant
        screeningDate
        updatedOn
        adminGroup
        score
        resultCode
        result
        qrcode
        fullAccessGroup
        facial
      }
      nextToken
      total
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      group
      tenantId
      enrollerConfigurationName
      placedBy
      orderDate
      updatedAt
      itemCode
      itemDescription
      quantity
      unitOfMeasure
      shipToAccount
      shipToName
      shipToAddress
      shipToState
      shipToCity
      shipToZip
      status
      updatedOn
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $id: ID
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOrders(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        group
        tenantId
        enrollerConfigurationName
        placedBy
        orderDate
        updatedAt
        itemCode
        itemDescription
        quantity
        unitOfMeasure
        shipToAccount
        shipToName
        shipToAddress
        shipToState
        shipToCity
        shipToZip
        status
        updatedOn
      }
      nextToken
    }
  }
`;
export const listOrdersByTenantIdAndDate = /* GraphQL */ `
  query ListOrdersByTenantIdAndDate(
    $tenantId: String
    $orderDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrdersByTenantIdAndDate(
      tenantId: $tenantId
      orderDate: $orderDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        tenantId
        enrollerConfigurationName
        placedBy
        orderDate
        updatedAt
        itemCode
        itemDescription
        quantity
        unitOfMeasure
        shipToAccount
        shipToName
        shipToAddress
        shipToState
        shipToCity
        shipToZip
        status
        updatedOn
      }
      nextToken
    }
  }
`;
export const getCodeTranslations = /* GraphQL */ `
  query GetCodeTranslations($tenant: ID!, $language: String!) {
    getCodeTranslations(tenant: $tenant, language: $language) {
      tenant
      language
      questions {
        id
        question
      }
      disclaimer
      analyticResults {
        id
        alias
        answer
        proceed
      }
      adminGroup
      createdAt
      updatedAt
      tenantObj {
        name
        description
        prettyName
        url
        analyticConfiguration {
          tenant
          questionConfiguration {
            id
            weight
            weightTrigger
          }
          resultConfiguration {
            id
            threshold
            emailAddressesToAlert
          }
          adminGroup
          createdAt
          updatedAt
          tenantObj {
            name
            description
            prettyName
            url
            analyticConfiguration {
              tenant
              questionConfiguration {
                id
                weight
                weightTrigger
              }
              resultConfiguration {
                id
                threshold
                emailAddressesToAlert
              }
              adminGroup
              createdAt
              updatedAt
              tenantObj {
                name
                description
                prettyName
                url
                adminGroup
                allowedRoles
                tenantAdminGroup
                fullReadAccess
                resultMetricEmailList
                resultMetricEmailTime
                questionnaireValidityTimeInSeconds
                latitude
                longitude
                fieldgroupid
                vizsafeApiUrl
                createdAt
                updatedAt
              }
            }
            adminGroup
            allowedRoles
            tenantAdminGroup
            fullReadAccess
            resultMetricEmailList
            resultMetricEmailTime
            questionnaireValidityTimeInSeconds
            latitude
            longitude
            fieldgroupid
            vizsafeApiUrl
            createdAt
            updatedAt
            codeTranslations {
              items {
                tenant
                language
                disclaimer
                adminGroup
                createdAt
                updatedAt
              }
              nextToken
            }
            invites {
              id
              emailAddress
              tenantId
              tenantProvidedUserId
              tenantRole
              tenantAdminGroup
              verificationCode
              verificationExpiration
              validated
              username
              firstName
              lastName
              profileImage
              createdAt
              updatedAt
            }
          }
        }
        adminGroup
        allowedRoles
        tenantAdminGroup
        fullReadAccess
        resultMetricEmailList
        resultMetricEmailTime
        questionnaireValidityTimeInSeconds
        latitude
        longitude
        fieldgroupid
        vizsafeApiUrl
        createdAt
        updatedAt
        codeTranslations {
          items {
            tenant
            language
            questions {
              id
              question
            }
            disclaimer
            analyticResults {
              id
              alias
              answer
              proceed
            }
            adminGroup
            createdAt
            updatedAt
            tenantObj {
              name
              description
              prettyName
              url
              analyticConfiguration {
                tenant
                adminGroup
                createdAt
                updatedAt
              }
              adminGroup
              allowedRoles
              tenantAdminGroup
              fullReadAccess
              resultMetricEmailList
              resultMetricEmailTime
              questionnaireValidityTimeInSeconds
              latitude
              longitude
              fieldgroupid
              vizsafeApiUrl
              createdAt
              updatedAt
              codeTranslations {
                nextToken
              }
              invites {
                id
                emailAddress
                tenantId
                tenantProvidedUserId
                tenantRole
                tenantAdminGroup
                verificationCode
                verificationExpiration
                validated
                username
                firstName
                lastName
                profileImage
                createdAt
                updatedAt
              }
            }
          }
          nextToken
        }
        invites {
          id
          emailAddress
          tenantId
          tenantProvidedUserId
          tenantRole
          tenantAdminGroup
          verificationCode
          verificationExpiration
          validated
          username
          firstName
          lastName
          profileImage
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listCodeTranslationss = /* GraphQL */ `
  query ListCodeTranslationss(
    $tenant: ID
    $language: ModelStringKeyConditionInput
    $filter: ModelCodeTranslationsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCodeTranslationss(
      tenant: $tenant
      language: $language
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tenant
        language
        questions {
          id
          question
        }
        disclaimer
        analyticResults {
          id
          alias
          answer
          proceed
        }
        adminGroup
        createdAt
        updatedAt
        tenantObj {
          name
          description
          prettyName
          url
          analyticConfiguration {
            tenant
            questionConfiguration {
              id
              weight
              weightTrigger
            }
            resultConfiguration {
              id
              threshold
              emailAddressesToAlert
            }
            adminGroup
            createdAt
            updatedAt
            tenantObj {
              name
              description
              prettyName
              url
              analyticConfiguration {
                tenant
                adminGroup
                createdAt
                updatedAt
              }
              adminGroup
              allowedRoles
              tenantAdminGroup
              fullReadAccess
              resultMetricEmailList
              resultMetricEmailTime
              questionnaireValidityTimeInSeconds
              latitude
              longitude
              fieldgroupid
              vizsafeApiUrl
              createdAt
              updatedAt
              codeTranslations {
                nextToken
              }
              invites {
                id
                emailAddress
                tenantId
                tenantProvidedUserId
                tenantRole
                tenantAdminGroup
                verificationCode
                verificationExpiration
                validated
                username
                firstName
                lastName
                profileImage
                createdAt
                updatedAt
              }
            }
          }
          adminGroup
          allowedRoles
          tenantAdminGroup
          fullReadAccess
          resultMetricEmailList
          resultMetricEmailTime
          questionnaireValidityTimeInSeconds
          latitude
          longitude
          fieldgroupid
          vizsafeApiUrl
          createdAt
          updatedAt
          codeTranslations {
            items {
              tenant
              language
              questions {
                id
                question
              }
              disclaimer
              analyticResults {
                id
                alias
                answer
                proceed
              }
              adminGroup
              createdAt
              updatedAt
              tenantObj {
                name
                description
                prettyName
                url
                adminGroup
                allowedRoles
                tenantAdminGroup
                fullReadAccess
                resultMetricEmailList
                resultMetricEmailTime
                questionnaireValidityTimeInSeconds
                latitude
                longitude
                fieldgroupid
                vizsafeApiUrl
                createdAt
                updatedAt
              }
            }
            nextToken
          }
          invites {
            id
            emailAddress
            tenantId
            tenantProvidedUserId
            tenantRole
            tenantAdminGroup
            verificationCode
            verificationExpiration
            validated
            username
            firstName
            lastName
            profileImage
            createdAt
            updatedAt
          }
        }
      }
      nextToken
    }
  }
`;
export const listTenants = /* GraphQL */ `
  query ListTenants(
    $name: ID
    $filter: ModelTenantFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTenants(
      name: $name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        name
        description
        prettyName
        url
        analyticConfiguration {
          tenant
          questionConfiguration {
            id
            weight
            weightTrigger
          }
          resultConfiguration {
            id
            threshold
            emailAddressesToAlert
          }
          adminGroup
          createdAt
          updatedAt
          tenantObj {
            name
            description
            prettyName
            url
            analyticConfiguration {
              tenant
              questionConfiguration {
                id
                weight
                weightTrigger
              }
              resultConfiguration {
                id
                threshold
                emailAddressesToAlert
              }
              adminGroup
              createdAt
              updatedAt
              tenantObj {
                name
                description
                prettyName
                url
                adminGroup
                allowedRoles
                tenantAdminGroup
                fullReadAccess
                resultMetricEmailList
                resultMetricEmailTime
                questionnaireValidityTimeInSeconds
                latitude
                longitude
                fieldgroupid
                vizsafeApiUrl
                createdAt
                updatedAt
              }
            }
            adminGroup
            allowedRoles
            tenantAdminGroup
            fullReadAccess
            resultMetricEmailList
            resultMetricEmailTime
            questionnaireValidityTimeInSeconds
            latitude
            longitude
            fieldgroupid
            vizsafeApiUrl
            createdAt
            updatedAt
            codeTranslations {
              items {
                tenant
                language
                disclaimer
                adminGroup
                createdAt
                updatedAt
              }
              nextToken
            }
            invites {
              id
              emailAddress
              tenantId
              tenantProvidedUserId
              tenantRole
              tenantAdminGroup
              verificationCode
              verificationExpiration
              validated
              username
              firstName
              lastName
              profileImage
              createdAt
              updatedAt
            }
          }
        }
        adminGroup
        allowedRoles
        tenantAdminGroup
        fullReadAccess
        resultMetricEmailList
        resultMetricEmailTime
        questionnaireValidityTimeInSeconds
        latitude
        longitude
        fieldgroupid
        vizsafeApiUrl
        createdAt
        updatedAt
        codeTranslations {
          items {
            tenant
            language
            questions {
              id
              question
            }
            disclaimer
            analyticResults {
              id
              alias
              answer
              proceed
            }
            adminGroup
            createdAt
            updatedAt
            tenantObj {
              name
              description
              prettyName
              url
              analyticConfiguration {
                tenant
                adminGroup
                createdAt
                updatedAt
              }
              adminGroup
              allowedRoles
              tenantAdminGroup
              fullReadAccess
              resultMetricEmailList
              resultMetricEmailTime
              questionnaireValidityTimeInSeconds
              latitude
              longitude
              fieldgroupid
              vizsafeApiUrl
              createdAt
              updatedAt
              codeTranslations {
                nextToken
              }
              invites {
                id
                emailAddress
                tenantId
                tenantProvidedUserId
                tenantRole
                tenantAdminGroup
                verificationCode
                verificationExpiration
                validated
                username
                firstName
                lastName
                profileImage
                createdAt
                updatedAt
              }
            }
          }
          nextToken
        }
        invites {
          id
          emailAddress
          tenantId
          tenantProvidedUserId
          tenantRole
          tenantAdminGroup
          verificationCode
          verificationExpiration
          validated
          username
          firstName
          lastName
          profileImage
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getTenant = /* GraphQL */ `
  query GetTenant($name: ID!) {
    getTenant(name: $name) {
      name
      description
      prettyName
      url
      analyticConfiguration {
        tenant
        questionConfiguration {
          id
          weight
          weightTrigger
        }
        resultConfiguration {
          id
          threshold
          emailAddressesToAlert
        }
        adminGroup
        createdAt
        updatedAt
        tenantObj {
          name
          description
          prettyName
          url
          analyticConfiguration {
            tenant
            questionConfiguration {
              id
              weight
              weightTrigger
            }
            resultConfiguration {
              id
              threshold
              emailAddressesToAlert
            }
            adminGroup
            createdAt
            updatedAt
            tenantObj {
              name
              description
              prettyName
              url
              analyticConfiguration {
                tenant
                adminGroup
                createdAt
                updatedAt
              }
              adminGroup
              allowedRoles
              tenantAdminGroup
              fullReadAccess
              resultMetricEmailList
              resultMetricEmailTime
              questionnaireValidityTimeInSeconds
              latitude
              longitude
              fieldgroupid
              vizsafeApiUrl
              createdAt
              updatedAt
              codeTranslations {
                nextToken
              }
              invites {
                id
                emailAddress
                tenantId
                tenantProvidedUserId
                tenantRole
                tenantAdminGroup
                verificationCode
                verificationExpiration
                validated
                username
                firstName
                lastName
                profileImage
                createdAt
                updatedAt
              }
            }
          }
          adminGroup
          allowedRoles
          tenantAdminGroup
          fullReadAccess
          resultMetricEmailList
          resultMetricEmailTime
          questionnaireValidityTimeInSeconds
          latitude
          longitude
          fieldgroupid
          vizsafeApiUrl
          createdAt
          updatedAt
          codeTranslations {
            items {
              tenant
              language
              questions {
                id
                question
              }
              disclaimer
              analyticResults {
                id
                alias
                answer
                proceed
              }
              adminGroup
              createdAt
              updatedAt
              tenantObj {
                name
                description
                prettyName
                url
                adminGroup
                allowedRoles
                tenantAdminGroup
                fullReadAccess
                resultMetricEmailList
                resultMetricEmailTime
                questionnaireValidityTimeInSeconds
                latitude
                longitude
                fieldgroupid
                vizsafeApiUrl
                createdAt
                updatedAt
              }
            }
            nextToken
          }
          invites {
            id
            emailAddress
            tenantId
            tenantProvidedUserId
            tenantRole
            tenantAdminGroup
            verificationCode
            verificationExpiration
            validated
            username
            firstName
            lastName
            profileImage
            createdAt
            updatedAt
          }
        }
      }
      adminGroup
      allowedRoles
      tenantAdminGroup
      fullReadAccess
      resultMetricEmailList
      resultMetricEmailTime
      questionnaireValidityTimeInSeconds
      latitude
      longitude
      fieldgroupid
      vizsafeApiUrl
      createdAt
      updatedAt
      codeTranslations {
        items {
          tenant
          language
          questions {
            id
            question
          }
          disclaimer
          analyticResults {
            id
            alias
            answer
            proceed
          }
          adminGroup
          createdAt
          updatedAt
          tenantObj {
            name
            description
            prettyName
            url
            analyticConfiguration {
              tenant
              questionConfiguration {
                id
                weight
                weightTrigger
              }
              resultConfiguration {
                id
                threshold
                emailAddressesToAlert
              }
              adminGroup
              createdAt
              updatedAt
              tenantObj {
                name
                description
                prettyName
                url
                adminGroup
                allowedRoles
                tenantAdminGroup
                fullReadAccess
                resultMetricEmailList
                resultMetricEmailTime
                questionnaireValidityTimeInSeconds
                latitude
                longitude
                fieldgroupid
                vizsafeApiUrl
                createdAt
                updatedAt
              }
            }
            adminGroup
            allowedRoles
            tenantAdminGroup
            fullReadAccess
            resultMetricEmailList
            resultMetricEmailTime
            questionnaireValidityTimeInSeconds
            latitude
            longitude
            fieldgroupid
            vizsafeApiUrl
            createdAt
            updatedAt
            codeTranslations {
              items {
                tenant
                language
                disclaimer
                adminGroup
                createdAt
                updatedAt
              }
              nextToken
            }
            invites {
              id
              emailAddress
              tenantId
              tenantProvidedUserId
              tenantRole
              tenantAdminGroup
              verificationCode
              verificationExpiration
              validated
              username
              firstName
              lastName
              profileImage
              createdAt
              updatedAt
            }
          }
        }
        nextToken
      }
      invites {
        id
        emailAddress
        tenantId
        tenantProvidedUserId
        tenantRole
        tenantAdminGroup
        verificationCode
        verificationExpiration
        validated
        username
        firstName
        lastName
        profileImage
        createdAt
        updatedAt
      }
    }
  }
`;
export const getUserIdentificationKey = /* GraphQL */ `
  query GetUserIdentificationKey($owner: ID!) {
    getUserIdentificationKey(owner: $owner) {
      owner
      identificationKey
      qrcode
      createdAt
      updatedAt
    }
  }
`;
export const listUserIdentificationKeys = /* GraphQL */ `
  query ListUserIdentificationKeys(
    $owner: ID
    $filter: ModelUserIdentificationKeyFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserIdentificationKeys(
      owner: $owner
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        owner
        identificationKey
        qrcode
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserIdentificationKeyByKey = /* GraphQL */ `
  query GetUserIdentificationKeyByKey(
    $identificationKey: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserIdentificationKeyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserIdentificationKeyByKey(
      identificationKey: $identificationKey
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        owner
        identificationKey
        qrcode
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUserTenantByTenant = /* GraphQL */ `
  query ListUserTenantByTenant(
    $tenantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelUserTenantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserTenantByTenant(
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userId
        tenantId
        tenantProvidedUserId
        tenantRole
        tenantAdminGroup
        firstName
        lastName
        type
        createdAt
        updatedAt
        tenantObj {
          name
          description
          prettyName
          url
          analyticConfiguration {
            tenant
            questionConfiguration {
              id
              weight
              weightTrigger
            }
            resultConfiguration {
              id
              threshold
              emailAddressesToAlert
            }
            adminGroup
            createdAt
            updatedAt
            tenantObj {
              name
              description
              prettyName
              url
              analyticConfiguration {
                tenant
                adminGroup
                createdAt
                updatedAt
              }
              adminGroup
              allowedRoles
              tenantAdminGroup
              fullReadAccess
              resultMetricEmailList
              resultMetricEmailTime
              questionnaireValidityTimeInSeconds
              latitude
              longitude
              fieldgroupid
              vizsafeApiUrl
              createdAt
              updatedAt
              codeTranslations {
                nextToken
              }
              invites {
                id
                emailAddress
                tenantId
                tenantProvidedUserId
                tenantRole
                tenantAdminGroup
                verificationCode
                verificationExpiration
                validated
                username
                firstName
                lastName
                profileImage
                createdAt
                updatedAt
              }
            }
          }
          adminGroup
          allowedRoles
          tenantAdminGroup
          fullReadAccess
          resultMetricEmailList
          resultMetricEmailTime
          questionnaireValidityTimeInSeconds
          latitude
          longitude
          fieldgroupid
          vizsafeApiUrl
          createdAt
          updatedAt
          codeTranslations {
            items {
              tenant
              language
              questions {
                id
                question
              }
              disclaimer
              analyticResults {
                id
                alias
                answer
                proceed
              }
              adminGroup
              createdAt
              updatedAt
              tenantObj {
                name
                description
                prettyName
                url
                adminGroup
                allowedRoles
                tenantAdminGroup
                fullReadAccess
                resultMetricEmailList
                resultMetricEmailTime
                questionnaireValidityTimeInSeconds
                latitude
                longitude
                fieldgroupid
                vizsafeApiUrl
                createdAt
                updatedAt
              }
            }
            nextToken
          }
          invites {
            id
            emailAddress
            tenantId
            tenantProvidedUserId
            tenantRole
            tenantAdminGroup
            verificationCode
            verificationExpiration
            validated
            username
            firstName
            lastName
            profileImage
            createdAt
            updatedAt
          }
        }
        emailVerified
        subs {
          items {
            userId
            tenantId
            subscribedTo
            resultCode
            tenantAdminGroup
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getVerifiedUserTenantsByTenant = /* GraphQL */ `
  query GetVerifiedUserTenantsByTenant(
    $tenantId: ID
    $emailVerified: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserTenantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getVerifiedUserTenantsByTenant(
      tenantId: $tenantId
      emailVerified: $emailVerified
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userId
        tenantId
        tenantProvidedUserId
        tenantRole
        tenantAdminGroup
        firstName
        lastName
        type
        createdAt
        updatedAt
        tenantObj {
          name
          description
          prettyName
          url
          analyticConfiguration {
            tenant
            questionConfiguration {
              id
              weight
              weightTrigger
            }
            resultConfiguration {
              id
              threshold
              emailAddressesToAlert
            }
            adminGroup
            createdAt
            updatedAt
            tenantObj {
              name
              description
              prettyName
              url
              analyticConfiguration {
                tenant
                adminGroup
                createdAt
                updatedAt
              }
              adminGroup
              allowedRoles
              tenantAdminGroup
              fullReadAccess
              resultMetricEmailList
              resultMetricEmailTime
              questionnaireValidityTimeInSeconds
              latitude
              longitude
              fieldgroupid
              vizsafeApiUrl
              createdAt
              updatedAt
              codeTranslations {
                nextToken
              }
              invites {
                id
                emailAddress
                tenantId
                tenantProvidedUserId
                tenantRole
                tenantAdminGroup
                verificationCode
                verificationExpiration
                validated
                username
                firstName
                lastName
                profileImage
                createdAt
                updatedAt
              }
            }
          }
          adminGroup
          allowedRoles
          tenantAdminGroup
          fullReadAccess
          resultMetricEmailList
          resultMetricEmailTime
          questionnaireValidityTimeInSeconds
          latitude
          longitude
          fieldgroupid
          vizsafeApiUrl
          createdAt
          updatedAt
          codeTranslations {
            items {
              tenant
              language
              questions {
                id
                question
              }
              disclaimer
              analyticResults {
                id
                alias
                answer
                proceed
              }
              adminGroup
              createdAt
              updatedAt
              tenantObj {
                name
                description
                prettyName
                url
                adminGroup
                allowedRoles
                tenantAdminGroup
                fullReadAccess
                resultMetricEmailList
                resultMetricEmailTime
                questionnaireValidityTimeInSeconds
                latitude
                longitude
                fieldgroupid
                vizsafeApiUrl
                createdAt
                updatedAt
              }
            }
            nextToken
          }
          invites {
            id
            emailAddress
            tenantId
            tenantProvidedUserId
            tenantRole
            tenantAdminGroup
            verificationCode
            verificationExpiration
            validated
            username
            firstName
            lastName
            profileImage
            createdAt
            updatedAt
          }
        }
        emailVerified
        subs {
          items {
            userId
            tenantId
            subscribedTo
            resultCode
            tenantAdminGroup
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getUserTenant = /* GraphQL */ `
  query GetUserTenant($userId: ID!, $tenantId: ID!) {
    getUserTenant(userId: $userId, tenantId: $tenantId) {
      userId
      tenantId
      tenantProvidedUserId
      tenantRole
      tenantAdminGroup
      firstName
      lastName
      type
      createdAt
      updatedAt
      tenantObj {
        name
        description
        prettyName
        url
        analyticConfiguration {
          tenant
          questionConfiguration {
            id
            weight
            weightTrigger
          }
          resultConfiguration {
            id
            threshold
            emailAddressesToAlert
          }
          adminGroup
          createdAt
          updatedAt
          tenantObj {
            name
            description
            prettyName
            url
            analyticConfiguration {
              tenant
              questionConfiguration {
                id
                weight
                weightTrigger
              }
              resultConfiguration {
                id
                threshold
                emailAddressesToAlert
              }
              adminGroup
              createdAt
              updatedAt
              tenantObj {
                name
                description
                prettyName
                url
                adminGroup
                allowedRoles
                tenantAdminGroup
                fullReadAccess
                resultMetricEmailList
                resultMetricEmailTime
                questionnaireValidityTimeInSeconds
                latitude
                longitude
                fieldgroupid
                vizsafeApiUrl
                createdAt
                updatedAt
              }
            }
            adminGroup
            allowedRoles
            tenantAdminGroup
            fullReadAccess
            resultMetricEmailList
            resultMetricEmailTime
            questionnaireValidityTimeInSeconds
            latitude
            longitude
            fieldgroupid
            vizsafeApiUrl
            createdAt
            updatedAt
            codeTranslations {
              items {
                tenant
                language
                disclaimer
                adminGroup
                createdAt
                updatedAt
              }
              nextToken
            }
            invites {
              id
              emailAddress
              tenantId
              tenantProvidedUserId
              tenantRole
              tenantAdminGroup
              verificationCode
              verificationExpiration
              validated
              username
              firstName
              lastName
              profileImage
              createdAt
              updatedAt
            }
          }
        }
        adminGroup
        allowedRoles
        tenantAdminGroup
        fullReadAccess
        resultMetricEmailList
        resultMetricEmailTime
        questionnaireValidityTimeInSeconds
        latitude
        longitude
        fieldgroupid
        vizsafeApiUrl
        createdAt
        updatedAt
        codeTranslations {
          items {
            tenant
            language
            questions {
              id
              question
            }
            disclaimer
            analyticResults {
              id
              alias
              answer
              proceed
            }
            adminGroup
            createdAt
            updatedAt
            tenantObj {
              name
              description
              prettyName
              url
              analyticConfiguration {
                tenant
                adminGroup
                createdAt
                updatedAt
              }
              adminGroup
              allowedRoles
              tenantAdminGroup
              fullReadAccess
              resultMetricEmailList
              resultMetricEmailTime
              questionnaireValidityTimeInSeconds
              latitude
              longitude
              fieldgroupid
              vizsafeApiUrl
              createdAt
              updatedAt
              codeTranslations {
                nextToken
              }
              invites {
                id
                emailAddress
                tenantId
                tenantProvidedUserId
                tenantRole
                tenantAdminGroup
                verificationCode
                verificationExpiration
                validated
                username
                firstName
                lastName
                profileImage
                createdAt
                updatedAt
              }
            }
          }
          nextToken
        }
        invites {
          id
          emailAddress
          tenantId
          tenantProvidedUserId
          tenantRole
          tenantAdminGroup
          verificationCode
          verificationExpiration
          validated
          username
          firstName
          lastName
          profileImage
          createdAt
          updatedAt
        }
      }
      emailVerified
      subs {
        items {
          userId
          tenantId
          subscribedTo
          resultCode
          tenantAdminGroup
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listUserTenants = /* GraphQL */ `
  query ListUserTenants(
    $userId: ID
    $tenantId: ModelIDKeyConditionInput
    $filter: ModelUserTenantFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserTenants(
      userId: $userId
      tenantId: $tenantId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userId
        tenantId
        tenantProvidedUserId
        tenantRole
        tenantAdminGroup
        firstName
        lastName
        type
        createdAt
        updatedAt
        tenantObj {
          name
          description
          prettyName
          url
          analyticConfiguration {
            tenant
            questionConfiguration {
              id
              weight
              weightTrigger
            }
            resultConfiguration {
              id
              threshold
              emailAddressesToAlert
            }
            adminGroup
            createdAt
            updatedAt
            tenantObj {
              name
              description
              prettyName
              url
              analyticConfiguration {
                tenant
                adminGroup
                createdAt
                updatedAt
              }
              adminGroup
              allowedRoles
              tenantAdminGroup
              fullReadAccess
              resultMetricEmailList
              resultMetricEmailTime
              questionnaireValidityTimeInSeconds
              latitude
              longitude
              fieldgroupid
              vizsafeApiUrl
              createdAt
              updatedAt
              codeTranslations {
                nextToken
              }
              invites {
                id
                emailAddress
                tenantId
                tenantProvidedUserId
                tenantRole
                tenantAdminGroup
                verificationCode
                verificationExpiration
                validated
                username
                firstName
                lastName
                profileImage
                createdAt
                updatedAt
              }
            }
          }
          adminGroup
          allowedRoles
          tenantAdminGroup
          fullReadAccess
          resultMetricEmailList
          resultMetricEmailTime
          questionnaireValidityTimeInSeconds
          latitude
          longitude
          fieldgroupid
          vizsafeApiUrl
          createdAt
          updatedAt
          codeTranslations {
            items {
              tenant
              language
              questions {
                id
                question
              }
              disclaimer
              analyticResults {
                id
                alias
                answer
                proceed
              }
              adminGroup
              createdAt
              updatedAt
              tenantObj {
                name
                description
                prettyName
                url
                adminGroup
                allowedRoles
                tenantAdminGroup
                fullReadAccess
                resultMetricEmailList
                resultMetricEmailTime
                questionnaireValidityTimeInSeconds
                latitude
                longitude
                fieldgroupid
                vizsafeApiUrl
                createdAt
                updatedAt
              }
            }
            nextToken
          }
          invites {
            id
            emailAddress
            tenantId
            tenantProvidedUserId
            tenantRole
            tenantAdminGroup
            verificationCode
            verificationExpiration
            validated
            username
            firstName
            lastName
            profileImage
            createdAt
            updatedAt
          }
        }
        emailVerified
        subs {
          items {
            userId
            tenantId
            subscribedTo
            resultCode
            tenantAdminGroup
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getEnrollerConfiguration = /* GraphQL */ `
  query GetEnrollerConfiguration($name: ID!) {
    getEnrollerConfiguration(name: $name) {
      tenantId
      name
      description
      prettyName
      enrollerGroup
      createdAt
      updatedAt
    }
  }
`;
export const listEnrollerConfigurations = /* GraphQL */ `
  query ListEnrollerConfigurations(
    $name: ID
    $filter: ModelEnrollerConfigurationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEnrollerConfigurations(
      name: $name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tenantId
        name
        description
        prettyName
        enrollerGroup
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMedicalTest = /* GraphQL */ `
  query GetMedicalTest($id: ID!) {
    getMedicalTest(id: $id) {
      id
      tenantId
      enrollerGroup
      enrolleeIdentifier
      collectedBy
      dateOfCollection
      enrolleeFirstName
      enrolleeLastName
      enrolleeDateOfBirth
      enrolleeRace
      enrolleeGender
      enrolleeEthnicity
      enrolleeAddress
      enrolleeAddress1
      enrolleeCounty
      enrolleeCity
      enrolleeState
      enrolleeZipCode
      enrolleePhone
      enrolleeEmailAddress
      collectionMethod
      orderingProviderNPI
      orderingProviderName
      orderingProviderZipCode
      orderingProviderPhone
      orderingProviderAddress
      orderingProviderAddress1
      orderingProviderClia
      sampleCode
      sampleType
      sampleSource
      panelCode
      panelName
      status
      result
      resultDate
      consent
      orderDate
      updateTime
    }
  }
`;
export const listMedicalTests = /* GraphQL */ `
  query ListMedicalTests(
    $id: ID
    $filter: ModelMedicalTestFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMedicalTests(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        tenantId
        enrollerGroup
        enrolleeIdentifier
        collectedBy
        dateOfCollection
        enrolleeFirstName
        enrolleeLastName
        enrolleeDateOfBirth
        enrolleeRace
        enrolleeGender
        enrolleeEthnicity
        enrolleeAddress
        enrolleeAddress1
        enrolleeCounty
        enrolleeCity
        enrolleeState
        enrolleeZipCode
        enrolleePhone
        enrolleeEmailAddress
        collectionMethod
        orderingProviderNPI
        orderingProviderName
        orderingProviderZipCode
        orderingProviderPhone
        orderingProviderAddress
        orderingProviderAddress1
        orderingProviderClia
        sampleCode
        sampleType
        sampleSource
        panelCode
        panelName
        status
        result
        resultDate
        consent
        orderDate
        updateTime
      }
      nextToken
    }
  }
`;
export const listMedicalTestsByTenantIdAndEnrolleeIdentifier = /* GraphQL */ `
  query ListMedicalTestsByTenantIdAndEnrolleeIdentifier(
    $tenantId: String
    $enrolleeIdentifier: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMedicalTestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMedicalTestsByTenantIdAndEnrolleeIdentifier(
      tenantId: $tenantId
      enrolleeIdentifier: $enrolleeIdentifier
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        enrollerGroup
        enrolleeIdentifier
        collectedBy
        dateOfCollection
        enrolleeFirstName
        enrolleeLastName
        enrolleeDateOfBirth
        enrolleeRace
        enrolleeGender
        enrolleeEthnicity
        enrolleeAddress
        enrolleeAddress1
        enrolleeCounty
        enrolleeCity
        enrolleeState
        enrolleeZipCode
        enrolleePhone
        enrolleeEmailAddress
        collectionMethod
        orderingProviderNPI
        orderingProviderName
        orderingProviderZipCode
        orderingProviderPhone
        orderingProviderAddress
        orderingProviderAddress1
        orderingProviderClia
        sampleCode
        sampleType
        sampleSource
        panelCode
        panelName
        status
        result
        resultDate
        consent
        orderDate
        updateTime
      }
      nextToken
    }
  }
`;
export const getUserSubscription = /* GraphQL */ `
  query GetUserSubscription(
    $userId: ID!
    $tenantId: ID!
    $subscribedTo: String!
    $resultCode: String!
  ) {
    getUserSubscription(
      userId: $userId
      tenantId: $tenantId
      subscribedTo: $subscribedTo
      resultCode: $resultCode
    ) {
      userId
      tenantId
      subscribedTo
      resultCode
      tenantAdminGroup
      createdAt
      updatedAt
    }
  }
`;
export const listUserSubscriptions = /* GraphQL */ `
  query ListUserSubscriptions(
    $userId: ID
    $tenantIdSubscribedToResultCode: ModelUserSubscriptionPrimaryCompositeKeyConditionInput
    $filter: ModelUserSubscriptionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserSubscriptions(
      userId: $userId
      tenantIdSubscribedToResultCode: $tenantIdSubscribedToResultCode
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userId
        tenantId
        subscribedTo
        resultCode
        tenantAdminGroup
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUserSubscriptionByUserIdAndTenantId = /* GraphQL */ `
  query ListUserSubscriptionByUserIdAndTenantId(
    $userId: ID
    $tenantId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserSubscriptionByUserIdAndTenantId(
      userId: $userId
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userId
        tenantId
        subscribedTo
        resultCode
        tenantAdminGroup
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBySubscribedToAndResultCodeAndTenantId = /* GraphQL */ `
  query GetBySubscribedToAndResultCodeAndTenantId(
    $subscribedTo: String
    $resultCodeTenantId: ModelUserSubscriptionGetBySubscribedToAndResultCodeAndTenantIdCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getBySubscribedToAndResultCodeAndTenantId(
      subscribedTo: $subscribedTo
      resultCodeTenantId: $resultCodeTenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userId
        tenantId
        subscribedTo
        resultCode
        tenantAdminGroup
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listTenantInvites = /* GraphQL */ `
  query ListTenantInvites(
    $filter: ModelTenantInviteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTenantInvites(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        emailAddress
        tenantId
        tenantProvidedUserId
        tenantRole
        tenantAdminGroup
        verificationCode
        verificationExpiration
        validated
        username
        firstName
        lastName
        profileImage
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTenantInvite = /* GraphQL */ `
  query GetTenantInvite($id: ID!) {
    getTenantInvite(id: $id) {
      id
      emailAddress
      tenantId
      tenantProvidedUserId
      tenantRole
      tenantAdminGroup
      verificationCode
      verificationExpiration
      validated
      username
      firstName
      lastName
      profileImage
      createdAt
      updatedAt
    }
  }
`;
export const getTenantInviteByTenantId = /* GraphQL */ `
  query GetTenantInviteByTenantId(
    $tenantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTenantInviteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTenantInviteByTenantId(
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        emailAddress
        tenantId
        tenantProvidedUserId
        tenantRole
        tenantAdminGroup
        verificationCode
        verificationExpiration
        validated
        username
        firstName
        lastName
        profileImage
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTenantInviteByTenantIdAndValidated = /* GraphQL */ `
  query GetTenantInviteByTenantIdAndValidated(
    $tenantId: ID
    $validated: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTenantInviteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTenantInviteByTenantIdAndValidated(
      tenantId: $tenantId
      validated: $validated
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        emailAddress
        tenantId
        tenantProvidedUserId
        tenantRole
        tenantAdminGroup
        verificationCode
        verificationExpiration
        validated
        username
        firstName
        lastName
        profileImage
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserHealthCard = /* GraphQL */ `
  query GetUserHealthCard($owner: ID!) {
    getUserHealthCard(owner: $owner) {
      owner
      medicalTestQRCode
      createdAt
      updatedAt
    }
  }
`;
export const listUserHealthCards = /* GraphQL */ `
  query ListUserHealthCards(
    $owner: ID
    $filter: ModelUserHealthCardFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserHealthCards(
      owner: $owner
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        owner
        medicalTestQRCode
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getObservation = /* GraphQL */ `
  query GetObservation($id: ID!) {
    getObservation(id: $id) {
      id
      userId
      tenant
      tenantManagerGroup
      tenantProvidedUserId
      firstName
      lastName
      email
      result
      category
      productName
      createdAt
      notes
      updatedAt
    }
  }
`;
export const listObservations = /* GraphQL */ `
  query ListObservations(
    $id: ID
    $filter: ModelObservationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listObservations(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userId
        tenant
        tenantManagerGroup
        tenantProvidedUserId
        firstName
        lastName
        email
        result
        category
        productName
        createdAt
        notes
        updatedAt
      }
      nextToken
    }
  }
`;
export const listObservationsByTenant = /* GraphQL */ `
  query ListObservationsByTenant(
    $tenant: String
    $createdAtId: ModelObservationListObservationsByTenantCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelObservationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listObservationsByTenant(
      tenant: $tenant
      createdAtId: $createdAtId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        tenant
        tenantManagerGroup
        tenantProvidedUserId
        firstName
        lastName
        email
        result
        category
        productName
        createdAt
        notes
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchObservations = /* GraphQL */ `
  query SearchObservations(
    $filter: SearchableObservationFilterInput
    $sort: SearchableObservationSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchObservations(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        userId
        tenant
        tenantManagerGroup
        tenantProvidedUserId
        firstName
        lastName
        email
        result
        category
        productName
        createdAt
        notes
        updatedAt
      }
      nextToken
      total
    }
  }
`;
