/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createQuestionnaireAndResult = /* GraphQL */ `
  mutation CreateQuestionnaireAndResult($input: CreateQuestionnaireInput) {
    createQuestionnaireAndResult(input: $input) {
      id
      questions {
        id
        question
        answer
      }
      sensorData {
        measurements {
          name
          unitOfMeasure
          measurement
        }
        source
      }
      reporters
      owner
      tenant
      screeningDate
      updatedOn
      adminGroup
      score
      resultCode
      result
      qrcode
      fullAccessGroup
      facial
    }
  }
`;
export const updateQuestionnaireAndResult = /* GraphQL */ `
  mutation UpdateQuestionnaireAndResult($input: UpdateQuestionnaireInput) {
    updateQuestionnaireAndResult(input: $input) {
      id
      questions {
        id
        question
        answer
      }
      sensorData {
        measurements {
          name
          unitOfMeasure
          measurement
        }
        source
      }
      reporters
      owner
      tenant
      screeningDate
      updatedOn
      adminGroup
      score
      resultCode
      result
      qrcode
      fullAccessGroup
      facial
    }
  }
`;
export const acceptInvitation = /* GraphQL */ `
  mutation AcceptInvitation($id: String, $verificationCode: String) {
    acceptInvitation(id: $id, verificationCode: $verificationCode) {
      inviteId
      username
    }
  }
`;
export const resendInvitationVerificationCode = /* GraphQL */ `
  mutation ResendInvitationVerificationCode($id: String) {
    resendInvitationVerificationCode(id: $id) {
      inviteId
      username
    }
  }
`;
export const createVisitorRequest = /* GraphQL */ `
  mutation CreateVisitorRequest($input: VisitorRequest) {
    createVisitorRequest(input: $input) {
      userId
      tenantId
      tenantProvidedUserId
      tenantRole
      tenantAdminGroup
      firstName
      lastName
      type
      createdAt
      updatedAt
      tenantObj {
        name
        description
        prettyName
        url
        analyticConfiguration {
          tenant
          questionConfiguration {
            id
            weight
            weightTrigger
          }
          resultConfiguration {
            id
            threshold
            emailAddressesToAlert
          }
          adminGroup
          createdAt
          updatedAt
          tenantObj {
            name
            description
            prettyName
            url
            analyticConfiguration {
              tenant
              questionConfiguration {
                id
                weight
                weightTrigger
              }
              resultConfiguration {
                id
                threshold
                emailAddressesToAlert
              }
              adminGroup
              createdAt
              updatedAt
              tenantObj {
                name
                description
                prettyName
                url
                adminGroup
                allowedRoles
                tenantAdminGroup
                fullReadAccess
                resultMetricEmailList
                resultMetricEmailTime
                questionnaireValidityTimeInSeconds
                latitude
                longitude
                fieldgroupid
                vizsafeApiUrl
                createdAt
                updatedAt
              }
            }
            adminGroup
            allowedRoles
            tenantAdminGroup
            fullReadAccess
            resultMetricEmailList
            resultMetricEmailTime
            questionnaireValidityTimeInSeconds
            latitude
            longitude
            fieldgroupid
            vizsafeApiUrl
            createdAt
            updatedAt
            codeTranslations {
              items {
                tenant
                language
                disclaimer
                adminGroup
                createdAt
                updatedAt
              }
              nextToken
            }
            invites {
              id
              emailAddress
              tenantId
              tenantProvidedUserId
              tenantRole
              tenantAdminGroup
              verificationCode
              verificationExpiration
              validated
              username
              firstName
              lastName
              profileImage
              createdAt
              updatedAt
            }
          }
        }
        adminGroup
        allowedRoles
        tenantAdminGroup
        fullReadAccess
        resultMetricEmailList
        resultMetricEmailTime
        questionnaireValidityTimeInSeconds
        latitude
        longitude
        fieldgroupid
        vizsafeApiUrl
        createdAt
        updatedAt
        codeTranslations {
          items {
            tenant
            language
            questions {
              id
              question
            }
            disclaimer
            analyticResults {
              id
              alias
              answer
              proceed
            }
            adminGroup
            createdAt
            updatedAt
            tenantObj {
              name
              description
              prettyName
              url
              analyticConfiguration {
                tenant
                adminGroup
                createdAt
                updatedAt
              }
              adminGroup
              allowedRoles
              tenantAdminGroup
              fullReadAccess
              resultMetricEmailList
              resultMetricEmailTime
              questionnaireValidityTimeInSeconds
              latitude
              longitude
              fieldgroupid
              vizsafeApiUrl
              createdAt
              updatedAt
              codeTranslations {
                nextToken
              }
              invites {
                id
                emailAddress
                tenantId
                tenantProvidedUserId
                tenantRole
                tenantAdminGroup
                verificationCode
                verificationExpiration
                validated
                username
                firstName
                lastName
                profileImage
                createdAt
                updatedAt
              }
            }
          }
          nextToken
        }
        invites {
          id
          emailAddress
          tenantId
          tenantProvidedUserId
          tenantRole
          tenantAdminGroup
          verificationCode
          verificationExpiration
          validated
          username
          firstName
          lastName
          profileImage
          createdAt
          updatedAt
        }
      }
      emailVerified
      subs {
        items {
          userId
          tenantId
          subscribedTo
          resultCode
          tenantAdminGroup
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      group
      tenantId
      enrollerConfigurationName
      placedBy
      orderDate
      updatedAt
      itemCode
      itemDescription
      quantity
      unitOfMeasure
      shipToAccount
      shipToName
      shipToAddress
      shipToState
      shipToCity
      shipToZip
      status
      updatedOn
    }
  }
`;
export const createQuestionnaire = /* GraphQL */ `
  mutation CreateQuestionnaire(
    $input: CreateQuestionnaireInput!
    $condition: ModelQuestionnaireConditionInput
  ) {
    createQuestionnaire(input: $input, condition: $condition) {
      id
      questions {
        id
        question
        answer
      }
      sensorData {
        measurements {
          name
          unitOfMeasure
          measurement
        }
        source
      }
      reporters
      owner
      tenant
      screeningDate
      updatedOn
      adminGroup
      score
      resultCode
      result
      qrcode
      fullAccessGroup
      facial
    }
  }
`;
export const updateQuestionnaire = /* GraphQL */ `
  mutation UpdateQuestionnaire(
    $input: UpdateQuestionnaireInput!
    $condition: ModelQuestionnaireConditionInput
  ) {
    updateQuestionnaire(input: $input, condition: $condition) {
      id
      questions {
        id
        question
        answer
      }
      sensorData {
        measurements {
          name
          unitOfMeasure
          measurement
        }
        source
      }
      reporters
      owner
      tenant
      screeningDate
      updatedOn
      adminGroup
      score
      resultCode
      result
      qrcode
      fullAccessGroup
      facial
    }
  }
`;
export const deleteQuestionnaire = /* GraphQL */ `
  mutation DeleteQuestionnaire(
    $input: DeleteQuestionnaireInput!
    $condition: ModelQuestionnaireConditionInput
  ) {
    deleteQuestionnaire(input: $input, condition: $condition) {
      id
      questions {
        id
        question
        answer
      }
      sensorData {
        measurements {
          name
          unitOfMeasure
          measurement
        }
        source
      }
      reporters
      owner
      tenant
      screeningDate
      updatedOn
      adminGroup
      score
      resultCode
      result
      qrcode
      fullAccessGroup
      facial
    }
  }
`;
export const createAnalyticConfiguration = /* GraphQL */ `
  mutation CreateAnalyticConfiguration(
    $input: CreateAnalyticConfigurationInput!
    $condition: ModelAnalyticConfigurationConditionInput
  ) {
    createAnalyticConfiguration(input: $input, condition: $condition) {
      tenant
      questionConfiguration {
        id
        weight
        weightTrigger
      }
      resultConfiguration {
        id
        threshold
        emailAddressesToAlert
      }
      adminGroup
      createdAt
      updatedAt
      tenantObj {
        name
        description
        prettyName
        url
        analyticConfiguration {
          tenant
          questionConfiguration {
            id
            weight
            weightTrigger
          }
          resultConfiguration {
            id
            threshold
            emailAddressesToAlert
          }
          adminGroup
          createdAt
          updatedAt
          tenantObj {
            name
            description
            prettyName
            url
            analyticConfiguration {
              tenant
              questionConfiguration {
                id
                weight
                weightTrigger
              }
              resultConfiguration {
                id
                threshold
                emailAddressesToAlert
              }
              adminGroup
              createdAt
              updatedAt
              tenantObj {
                name
                description
                prettyName
                url
                adminGroup
                allowedRoles
                tenantAdminGroup
                fullReadAccess
                resultMetricEmailList
                resultMetricEmailTime
                questionnaireValidityTimeInSeconds
                latitude
                longitude
                fieldgroupid
                vizsafeApiUrl
                createdAt
                updatedAt
              }
            }
            adminGroup
            allowedRoles
            tenantAdminGroup
            fullReadAccess
            resultMetricEmailList
            resultMetricEmailTime
            questionnaireValidityTimeInSeconds
            latitude
            longitude
            fieldgroupid
            vizsafeApiUrl
            createdAt
            updatedAt
            codeTranslations {
              items {
                tenant
                language
                disclaimer
                adminGroup
                createdAt
                updatedAt
              }
              nextToken
            }
            invites {
              id
              emailAddress
              tenantId
              tenantProvidedUserId
              tenantRole
              tenantAdminGroup
              verificationCode
              verificationExpiration
              validated
              username
              firstName
              lastName
              profileImage
              createdAt
              updatedAt
            }
          }
        }
        adminGroup
        allowedRoles
        tenantAdminGroup
        fullReadAccess
        resultMetricEmailList
        resultMetricEmailTime
        questionnaireValidityTimeInSeconds
        latitude
        longitude
        fieldgroupid
        vizsafeApiUrl
        createdAt
        updatedAt
        codeTranslations {
          items {
            tenant
            language
            questions {
              id
              question
            }
            disclaimer
            analyticResults {
              id
              alias
              answer
              proceed
            }
            adminGroup
            createdAt
            updatedAt
            tenantObj {
              name
              description
              prettyName
              url
              analyticConfiguration {
                tenant
                adminGroup
                createdAt
                updatedAt
              }
              adminGroup
              allowedRoles
              tenantAdminGroup
              fullReadAccess
              resultMetricEmailList
              resultMetricEmailTime
              questionnaireValidityTimeInSeconds
              latitude
              longitude
              fieldgroupid
              vizsafeApiUrl
              createdAt
              updatedAt
              codeTranslations {
                nextToken
              }
              invites {
                id
                emailAddress
                tenantId
                tenantProvidedUserId
                tenantRole
                tenantAdminGroup
                verificationCode
                verificationExpiration
                validated
                username
                firstName
                lastName
                profileImage
                createdAt
                updatedAt
              }
            }
          }
          nextToken
        }
        invites {
          id
          emailAddress
          tenantId
          tenantProvidedUserId
          tenantRole
          tenantAdminGroup
          verificationCode
          verificationExpiration
          validated
          username
          firstName
          lastName
          profileImage
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateAnalyticConfiguration = /* GraphQL */ `
  mutation UpdateAnalyticConfiguration(
    $input: UpdateAnalyticConfigurationInput!
    $condition: ModelAnalyticConfigurationConditionInput
  ) {
    updateAnalyticConfiguration(input: $input, condition: $condition) {
      tenant
      questionConfiguration {
        id
        weight
        weightTrigger
      }
      resultConfiguration {
        id
        threshold
        emailAddressesToAlert
      }
      adminGroup
      createdAt
      updatedAt
      tenantObj {
        name
        description
        prettyName
        url
        analyticConfiguration {
          tenant
          questionConfiguration {
            id
            weight
            weightTrigger
          }
          resultConfiguration {
            id
            threshold
            emailAddressesToAlert
          }
          adminGroup
          createdAt
          updatedAt
          tenantObj {
            name
            description
            prettyName
            url
            analyticConfiguration {
              tenant
              questionConfiguration {
                id
                weight
                weightTrigger
              }
              resultConfiguration {
                id
                threshold
                emailAddressesToAlert
              }
              adminGroup
              createdAt
              updatedAt
              tenantObj {
                name
                description
                prettyName
                url
                adminGroup
                allowedRoles
                tenantAdminGroup
                fullReadAccess
                resultMetricEmailList
                resultMetricEmailTime
                questionnaireValidityTimeInSeconds
                latitude
                longitude
                fieldgroupid
                vizsafeApiUrl
                createdAt
                updatedAt
              }
            }
            adminGroup
            allowedRoles
            tenantAdminGroup
            fullReadAccess
            resultMetricEmailList
            resultMetricEmailTime
            questionnaireValidityTimeInSeconds
            latitude
            longitude
            fieldgroupid
            vizsafeApiUrl
            createdAt
            updatedAt
            codeTranslations {
              items {
                tenant
                language
                disclaimer
                adminGroup
                createdAt
                updatedAt
              }
              nextToken
            }
            invites {
              id
              emailAddress
              tenantId
              tenantProvidedUserId
              tenantRole
              tenantAdminGroup
              verificationCode
              verificationExpiration
              validated
              username
              firstName
              lastName
              profileImage
              createdAt
              updatedAt
            }
          }
        }
        adminGroup
        allowedRoles
        tenantAdminGroup
        fullReadAccess
        resultMetricEmailList
        resultMetricEmailTime
        questionnaireValidityTimeInSeconds
        latitude
        longitude
        fieldgroupid
        vizsafeApiUrl
        createdAt
        updatedAt
        codeTranslations {
          items {
            tenant
            language
            questions {
              id
              question
            }
            disclaimer
            analyticResults {
              id
              alias
              answer
              proceed
            }
            adminGroup
            createdAt
            updatedAt
            tenantObj {
              name
              description
              prettyName
              url
              analyticConfiguration {
                tenant
                adminGroup
                createdAt
                updatedAt
              }
              adminGroup
              allowedRoles
              tenantAdminGroup
              fullReadAccess
              resultMetricEmailList
              resultMetricEmailTime
              questionnaireValidityTimeInSeconds
              latitude
              longitude
              fieldgroupid
              vizsafeApiUrl
              createdAt
              updatedAt
              codeTranslations {
                nextToken
              }
              invites {
                id
                emailAddress
                tenantId
                tenantProvidedUserId
                tenantRole
                tenantAdminGroup
                verificationCode
                verificationExpiration
                validated
                username
                firstName
                lastName
                profileImage
                createdAt
                updatedAt
              }
            }
          }
          nextToken
        }
        invites {
          id
          emailAddress
          tenantId
          tenantProvidedUserId
          tenantRole
          tenantAdminGroup
          verificationCode
          verificationExpiration
          validated
          username
          firstName
          lastName
          profileImage
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteAnalyticConfiguration = /* GraphQL */ `
  mutation DeleteAnalyticConfiguration(
    $input: DeleteAnalyticConfigurationInput!
    $condition: ModelAnalyticConfigurationConditionInput
  ) {
    deleteAnalyticConfiguration(input: $input, condition: $condition) {
      tenant
      questionConfiguration {
        id
        weight
        weightTrigger
      }
      resultConfiguration {
        id
        threshold
        emailAddressesToAlert
      }
      adminGroup
      createdAt
      updatedAt
      tenantObj {
        name
        description
        prettyName
        url
        analyticConfiguration {
          tenant
          questionConfiguration {
            id
            weight
            weightTrigger
          }
          resultConfiguration {
            id
            threshold
            emailAddressesToAlert
          }
          adminGroup
          createdAt
          updatedAt
          tenantObj {
            name
            description
            prettyName
            url
            analyticConfiguration {
              tenant
              questionConfiguration {
                id
                weight
                weightTrigger
              }
              resultConfiguration {
                id
                threshold
                emailAddressesToAlert
              }
              adminGroup
              createdAt
              updatedAt
              tenantObj {
                name
                description
                prettyName
                url
                adminGroup
                allowedRoles
                tenantAdminGroup
                fullReadAccess
                resultMetricEmailList
                resultMetricEmailTime
                questionnaireValidityTimeInSeconds
                latitude
                longitude
                fieldgroupid
                vizsafeApiUrl
                createdAt
                updatedAt
              }
            }
            adminGroup
            allowedRoles
            tenantAdminGroup
            fullReadAccess
            resultMetricEmailList
            resultMetricEmailTime
            questionnaireValidityTimeInSeconds
            latitude
            longitude
            fieldgroupid
            vizsafeApiUrl
            createdAt
            updatedAt
            codeTranslations {
              items {
                tenant
                language
                disclaimer
                adminGroup
                createdAt
                updatedAt
              }
              nextToken
            }
            invites {
              id
              emailAddress
              tenantId
              tenantProvidedUserId
              tenantRole
              tenantAdminGroup
              verificationCode
              verificationExpiration
              validated
              username
              firstName
              lastName
              profileImage
              createdAt
              updatedAt
            }
          }
        }
        adminGroup
        allowedRoles
        tenantAdminGroup
        fullReadAccess
        resultMetricEmailList
        resultMetricEmailTime
        questionnaireValidityTimeInSeconds
        latitude
        longitude
        fieldgroupid
        vizsafeApiUrl
        createdAt
        updatedAt
        codeTranslations {
          items {
            tenant
            language
            questions {
              id
              question
            }
            disclaimer
            analyticResults {
              id
              alias
              answer
              proceed
            }
            adminGroup
            createdAt
            updatedAt
            tenantObj {
              name
              description
              prettyName
              url
              analyticConfiguration {
                tenant
                adminGroup
                createdAt
                updatedAt
              }
              adminGroup
              allowedRoles
              tenantAdminGroup
              fullReadAccess
              resultMetricEmailList
              resultMetricEmailTime
              questionnaireValidityTimeInSeconds
              latitude
              longitude
              fieldgroupid
              vizsafeApiUrl
              createdAt
              updatedAt
              codeTranslations {
                nextToken
              }
              invites {
                id
                emailAddress
                tenantId
                tenantProvidedUserId
                tenantRole
                tenantAdminGroup
                verificationCode
                verificationExpiration
                validated
                username
                firstName
                lastName
                profileImage
                createdAt
                updatedAt
              }
            }
          }
          nextToken
        }
        invites {
          id
          emailAddress
          tenantId
          tenantProvidedUserId
          tenantRole
          tenantAdminGroup
          verificationCode
          verificationExpiration
          validated
          username
          firstName
          lastName
          profileImage
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createCodeTranslations = /* GraphQL */ `
  mutation CreateCodeTranslations(
    $input: CreateCodeTranslationsInput!
    $condition: ModelCodeTranslationsConditionInput
  ) {
    createCodeTranslations(input: $input, condition: $condition) {
      tenant
      language
      questions {
        id
        question
      }
      disclaimer
      analyticResults {
        id
        alias
        answer
        proceed
      }
      adminGroup
      createdAt
      updatedAt
      tenantObj {
        name
        description
        prettyName
        url
        analyticConfiguration {
          tenant
          questionConfiguration {
            id
            weight
            weightTrigger
          }
          resultConfiguration {
            id
            threshold
            emailAddressesToAlert
          }
          adminGroup
          createdAt
          updatedAt
          tenantObj {
            name
            description
            prettyName
            url
            analyticConfiguration {
              tenant
              questionConfiguration {
                id
                weight
                weightTrigger
              }
              resultConfiguration {
                id
                threshold
                emailAddressesToAlert
              }
              adminGroup
              createdAt
              updatedAt
              tenantObj {
                name
                description
                prettyName
                url
                adminGroup
                allowedRoles
                tenantAdminGroup
                fullReadAccess
                resultMetricEmailList
                resultMetricEmailTime
                questionnaireValidityTimeInSeconds
                latitude
                longitude
                fieldgroupid
                vizsafeApiUrl
                createdAt
                updatedAt
              }
            }
            adminGroup
            allowedRoles
            tenantAdminGroup
            fullReadAccess
            resultMetricEmailList
            resultMetricEmailTime
            questionnaireValidityTimeInSeconds
            latitude
            longitude
            fieldgroupid
            vizsafeApiUrl
            createdAt
            updatedAt
            codeTranslations {
              items {
                tenant
                language
                disclaimer
                adminGroup
                createdAt
                updatedAt
              }
              nextToken
            }
            invites {
              id
              emailAddress
              tenantId
              tenantProvidedUserId
              tenantRole
              tenantAdminGroup
              verificationCode
              verificationExpiration
              validated
              username
              firstName
              lastName
              profileImage
              createdAt
              updatedAt
            }
          }
        }
        adminGroup
        allowedRoles
        tenantAdminGroup
        fullReadAccess
        resultMetricEmailList
        resultMetricEmailTime
        questionnaireValidityTimeInSeconds
        latitude
        longitude
        fieldgroupid
        vizsafeApiUrl
        createdAt
        updatedAt
        codeTranslations {
          items {
            tenant
            language
            questions {
              id
              question
            }
            disclaimer
            analyticResults {
              id
              alias
              answer
              proceed
            }
            adminGroup
            createdAt
            updatedAt
            tenantObj {
              name
              description
              prettyName
              url
              analyticConfiguration {
                tenant
                adminGroup
                createdAt
                updatedAt
              }
              adminGroup
              allowedRoles
              tenantAdminGroup
              fullReadAccess
              resultMetricEmailList
              resultMetricEmailTime
              questionnaireValidityTimeInSeconds
              latitude
              longitude
              fieldgroupid
              vizsafeApiUrl
              createdAt
              updatedAt
              codeTranslations {
                nextToken
              }
              invites {
                id
                emailAddress
                tenantId
                tenantProvidedUserId
                tenantRole
                tenantAdminGroup
                verificationCode
                verificationExpiration
                validated
                username
                firstName
                lastName
                profileImage
                createdAt
                updatedAt
              }
            }
          }
          nextToken
        }
        invites {
          id
          emailAddress
          tenantId
          tenantProvidedUserId
          tenantRole
          tenantAdminGroup
          verificationCode
          verificationExpiration
          validated
          username
          firstName
          lastName
          profileImage
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateCodeTranslations = /* GraphQL */ `
  mutation UpdateCodeTranslations(
    $input: UpdateCodeTranslationsInput!
    $condition: ModelCodeTranslationsConditionInput
  ) {
    updateCodeTranslations(input: $input, condition: $condition) {
      tenant
      language
      questions {
        id
        question
      }
      disclaimer
      analyticResults {
        id
        alias
        answer
        proceed
      }
      adminGroup
      createdAt
      updatedAt
      tenantObj {
        name
        description
        prettyName
        url
        analyticConfiguration {
          tenant
          questionConfiguration {
            id
            weight
            weightTrigger
          }
          resultConfiguration {
            id
            threshold
            emailAddressesToAlert
          }
          adminGroup
          createdAt
          updatedAt
          tenantObj {
            name
            description
            prettyName
            url
            analyticConfiguration {
              tenant
              questionConfiguration {
                id
                weight
                weightTrigger
              }
              resultConfiguration {
                id
                threshold
                emailAddressesToAlert
              }
              adminGroup
              createdAt
              updatedAt
              tenantObj {
                name
                description
                prettyName
                url
                adminGroup
                allowedRoles
                tenantAdminGroup
                fullReadAccess
                resultMetricEmailList
                resultMetricEmailTime
                questionnaireValidityTimeInSeconds
                latitude
                longitude
                fieldgroupid
                vizsafeApiUrl
                createdAt
                updatedAt
              }
            }
            adminGroup
            allowedRoles
            tenantAdminGroup
            fullReadAccess
            resultMetricEmailList
            resultMetricEmailTime
            questionnaireValidityTimeInSeconds
            latitude
            longitude
            fieldgroupid
            vizsafeApiUrl
            createdAt
            updatedAt
            codeTranslations {
              items {
                tenant
                language
                disclaimer
                adminGroup
                createdAt
                updatedAt
              }
              nextToken
            }
            invites {
              id
              emailAddress
              tenantId
              tenantProvidedUserId
              tenantRole
              tenantAdminGroup
              verificationCode
              verificationExpiration
              validated
              username
              firstName
              lastName
              profileImage
              createdAt
              updatedAt
            }
          }
        }
        adminGroup
        allowedRoles
        tenantAdminGroup
        fullReadAccess
        resultMetricEmailList
        resultMetricEmailTime
        questionnaireValidityTimeInSeconds
        latitude
        longitude
        fieldgroupid
        vizsafeApiUrl
        createdAt
        updatedAt
        codeTranslations {
          items {
            tenant
            language
            questions {
              id
              question
            }
            disclaimer
            analyticResults {
              id
              alias
              answer
              proceed
            }
            adminGroup
            createdAt
            updatedAt
            tenantObj {
              name
              description
              prettyName
              url
              analyticConfiguration {
                tenant
                adminGroup
                createdAt
                updatedAt
              }
              adminGroup
              allowedRoles
              tenantAdminGroup
              fullReadAccess
              resultMetricEmailList
              resultMetricEmailTime
              questionnaireValidityTimeInSeconds
              latitude
              longitude
              fieldgroupid
              vizsafeApiUrl
              createdAt
              updatedAt
              codeTranslations {
                nextToken
              }
              invites {
                id
                emailAddress
                tenantId
                tenantProvidedUserId
                tenantRole
                tenantAdminGroup
                verificationCode
                verificationExpiration
                validated
                username
                firstName
                lastName
                profileImage
                createdAt
                updatedAt
              }
            }
          }
          nextToken
        }
        invites {
          id
          emailAddress
          tenantId
          tenantProvidedUserId
          tenantRole
          tenantAdminGroup
          verificationCode
          verificationExpiration
          validated
          username
          firstName
          lastName
          profileImage
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteCodeTranslations = /* GraphQL */ `
  mutation DeleteCodeTranslations(
    $input: DeleteCodeTranslationsInput!
    $condition: ModelCodeTranslationsConditionInput
  ) {
    deleteCodeTranslations(input: $input, condition: $condition) {
      tenant
      language
      questions {
        id
        question
      }
      disclaimer
      analyticResults {
        id
        alias
        answer
        proceed
      }
      adminGroup
      createdAt
      updatedAt
      tenantObj {
        name
        description
        prettyName
        url
        analyticConfiguration {
          tenant
          questionConfiguration {
            id
            weight
            weightTrigger
          }
          resultConfiguration {
            id
            threshold
            emailAddressesToAlert
          }
          adminGroup
          createdAt
          updatedAt
          tenantObj {
            name
            description
            prettyName
            url
            analyticConfiguration {
              tenant
              questionConfiguration {
                id
                weight
                weightTrigger
              }
              resultConfiguration {
                id
                threshold
                emailAddressesToAlert
              }
              adminGroup
              createdAt
              updatedAt
              tenantObj {
                name
                description
                prettyName
                url
                adminGroup
                allowedRoles
                tenantAdminGroup
                fullReadAccess
                resultMetricEmailList
                resultMetricEmailTime
                questionnaireValidityTimeInSeconds
                latitude
                longitude
                fieldgroupid
                vizsafeApiUrl
                createdAt
                updatedAt
              }
            }
            adminGroup
            allowedRoles
            tenantAdminGroup
            fullReadAccess
            resultMetricEmailList
            resultMetricEmailTime
            questionnaireValidityTimeInSeconds
            latitude
            longitude
            fieldgroupid
            vizsafeApiUrl
            createdAt
            updatedAt
            codeTranslations {
              items {
                tenant
                language
                disclaimer
                adminGroup
                createdAt
                updatedAt
              }
              nextToken
            }
            invites {
              id
              emailAddress
              tenantId
              tenantProvidedUserId
              tenantRole
              tenantAdminGroup
              verificationCode
              verificationExpiration
              validated
              username
              firstName
              lastName
              profileImage
              createdAt
              updatedAt
            }
          }
        }
        adminGroup
        allowedRoles
        tenantAdminGroup
        fullReadAccess
        resultMetricEmailList
        resultMetricEmailTime
        questionnaireValidityTimeInSeconds
        latitude
        longitude
        fieldgroupid
        vizsafeApiUrl
        createdAt
        updatedAt
        codeTranslations {
          items {
            tenant
            language
            questions {
              id
              question
            }
            disclaimer
            analyticResults {
              id
              alias
              answer
              proceed
            }
            adminGroup
            createdAt
            updatedAt
            tenantObj {
              name
              description
              prettyName
              url
              analyticConfiguration {
                tenant
                adminGroup
                createdAt
                updatedAt
              }
              adminGroup
              allowedRoles
              tenantAdminGroup
              fullReadAccess
              resultMetricEmailList
              resultMetricEmailTime
              questionnaireValidityTimeInSeconds
              latitude
              longitude
              fieldgroupid
              vizsafeApiUrl
              createdAt
              updatedAt
              codeTranslations {
                nextToken
              }
              invites {
                id
                emailAddress
                tenantId
                tenantProvidedUserId
                tenantRole
                tenantAdminGroup
                verificationCode
                verificationExpiration
                validated
                username
                firstName
                lastName
                profileImage
                createdAt
                updatedAt
              }
            }
          }
          nextToken
        }
        invites {
          id
          emailAddress
          tenantId
          tenantProvidedUserId
          tenantRole
          tenantAdminGroup
          verificationCode
          verificationExpiration
          validated
          username
          firstName
          lastName
          profileImage
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createTenant = /* GraphQL */ `
  mutation CreateTenant(
    $input: CreateTenantInput!
    $condition: ModelTenantConditionInput
  ) {
    createTenant(input: $input, condition: $condition) {
      name
      description
      prettyName
      url
      analyticConfiguration {
        tenant
        questionConfiguration {
          id
          weight
          weightTrigger
        }
        resultConfiguration {
          id
          threshold
          emailAddressesToAlert
        }
        adminGroup
        createdAt
        updatedAt
        tenantObj {
          name
          description
          prettyName
          url
          analyticConfiguration {
            tenant
            questionConfiguration {
              id
              weight
              weightTrigger
            }
            resultConfiguration {
              id
              threshold
              emailAddressesToAlert
            }
            adminGroup
            createdAt
            updatedAt
            tenantObj {
              name
              description
              prettyName
              url
              analyticConfiguration {
                tenant
                adminGroup
                createdAt
                updatedAt
              }
              adminGroup
              allowedRoles
              tenantAdminGroup
              fullReadAccess
              resultMetricEmailList
              resultMetricEmailTime
              questionnaireValidityTimeInSeconds
              latitude
              longitude
              fieldgroupid
              vizsafeApiUrl
              createdAt
              updatedAt
              codeTranslations {
                nextToken
              }
              invites {
                id
                emailAddress
                tenantId
                tenantProvidedUserId
                tenantRole
                tenantAdminGroup
                verificationCode
                verificationExpiration
                validated
                username
                firstName
                lastName
                profileImage
                createdAt
                updatedAt
              }
            }
          }
          adminGroup
          allowedRoles
          tenantAdminGroup
          fullReadAccess
          resultMetricEmailList
          resultMetricEmailTime
          questionnaireValidityTimeInSeconds
          latitude
          longitude
          fieldgroupid
          vizsafeApiUrl
          createdAt
          updatedAt
          codeTranslations {
            items {
              tenant
              language
              questions {
                id
                question
              }
              disclaimer
              analyticResults {
                id
                alias
                answer
                proceed
              }
              adminGroup
              createdAt
              updatedAt
              tenantObj {
                name
                description
                prettyName
                url
                adminGroup
                allowedRoles
                tenantAdminGroup
                fullReadAccess
                resultMetricEmailList
                resultMetricEmailTime
                questionnaireValidityTimeInSeconds
                latitude
                longitude
                fieldgroupid
                vizsafeApiUrl
                createdAt
                updatedAt
              }
            }
            nextToken
          }
          invites {
            id
            emailAddress
            tenantId
            tenantProvidedUserId
            tenantRole
            tenantAdminGroup
            verificationCode
            verificationExpiration
            validated
            username
            firstName
            lastName
            profileImage
            createdAt
            updatedAt
          }
        }
      }
      adminGroup
      allowedRoles
      tenantAdminGroup
      fullReadAccess
      resultMetricEmailList
      resultMetricEmailTime
      questionnaireValidityTimeInSeconds
      latitude
      longitude
      fieldgroupid
      vizsafeApiUrl
      createdAt
      updatedAt
      codeTranslations {
        items {
          tenant
          language
          questions {
            id
            question
          }
          disclaimer
          analyticResults {
            id
            alias
            answer
            proceed
          }
          adminGroup
          createdAt
          updatedAt
          tenantObj {
            name
            description
            prettyName
            url
            analyticConfiguration {
              tenant
              questionConfiguration {
                id
                weight
                weightTrigger
              }
              resultConfiguration {
                id
                threshold
                emailAddressesToAlert
              }
              adminGroup
              createdAt
              updatedAt
              tenantObj {
                name
                description
                prettyName
                url
                adminGroup
                allowedRoles
                tenantAdminGroup
                fullReadAccess
                resultMetricEmailList
                resultMetricEmailTime
                questionnaireValidityTimeInSeconds
                latitude
                longitude
                fieldgroupid
                vizsafeApiUrl
                createdAt
                updatedAt
              }
            }
            adminGroup
            allowedRoles
            tenantAdminGroup
            fullReadAccess
            resultMetricEmailList
            resultMetricEmailTime
            questionnaireValidityTimeInSeconds
            latitude
            longitude
            fieldgroupid
            vizsafeApiUrl
            createdAt
            updatedAt
            codeTranslations {
              items {
                tenant
                language
                disclaimer
                adminGroup
                createdAt
                updatedAt
              }
              nextToken
            }
            invites {
              id
              emailAddress
              tenantId
              tenantProvidedUserId
              tenantRole
              tenantAdminGroup
              verificationCode
              verificationExpiration
              validated
              username
              firstName
              lastName
              profileImage
              createdAt
              updatedAt
            }
          }
        }
        nextToken
      }
      invites {
        id
        emailAddress
        tenantId
        tenantProvidedUserId
        tenantRole
        tenantAdminGroup
        verificationCode
        verificationExpiration
        validated
        username
        firstName
        lastName
        profileImage
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateTenant = /* GraphQL */ `
  mutation UpdateTenant(
    $input: UpdateTenantInput!
    $condition: ModelTenantConditionInput
  ) {
    updateTenant(input: $input, condition: $condition) {
      name
      description
      prettyName
      url
      analyticConfiguration {
        tenant
        questionConfiguration {
          id
          weight
          weightTrigger
        }
        resultConfiguration {
          id
          threshold
          emailAddressesToAlert
        }
        adminGroup
        createdAt
        updatedAt
        tenantObj {
          name
          description
          prettyName
          url
          analyticConfiguration {
            tenant
            questionConfiguration {
              id
              weight
              weightTrigger
            }
            resultConfiguration {
              id
              threshold
              emailAddressesToAlert
            }
            adminGroup
            createdAt
            updatedAt
            tenantObj {
              name
              description
              prettyName
              url
              analyticConfiguration {
                tenant
                adminGroup
                createdAt
                updatedAt
              }
              adminGroup
              allowedRoles
              tenantAdminGroup
              fullReadAccess
              resultMetricEmailList
              resultMetricEmailTime
              questionnaireValidityTimeInSeconds
              latitude
              longitude
              fieldgroupid
              vizsafeApiUrl
              createdAt
              updatedAt
              codeTranslations {
                nextToken
              }
              invites {
                id
                emailAddress
                tenantId
                tenantProvidedUserId
                tenantRole
                tenantAdminGroup
                verificationCode
                verificationExpiration
                validated
                username
                firstName
                lastName
                profileImage
                createdAt
                updatedAt
              }
            }
          }
          adminGroup
          allowedRoles
          tenantAdminGroup
          fullReadAccess
          resultMetricEmailList
          resultMetricEmailTime
          questionnaireValidityTimeInSeconds
          latitude
          longitude
          fieldgroupid
          vizsafeApiUrl
          createdAt
          updatedAt
          codeTranslations {
            items {
              tenant
              language
              questions {
                id
                question
              }
              disclaimer
              analyticResults {
                id
                alias
                answer
                proceed
              }
              adminGroup
              createdAt
              updatedAt
              tenantObj {
                name
                description
                prettyName
                url
                adminGroup
                allowedRoles
                tenantAdminGroup
                fullReadAccess
                resultMetricEmailList
                resultMetricEmailTime
                questionnaireValidityTimeInSeconds
                latitude
                longitude
                fieldgroupid
                vizsafeApiUrl
                createdAt
                updatedAt
              }
            }
            nextToken
          }
          invites {
            id
            emailAddress
            tenantId
            tenantProvidedUserId
            tenantRole
            tenantAdminGroup
            verificationCode
            verificationExpiration
            validated
            username
            firstName
            lastName
            profileImage
            createdAt
            updatedAt
          }
        }
      }
      adminGroup
      allowedRoles
      tenantAdminGroup
      fullReadAccess
      resultMetricEmailList
      resultMetricEmailTime
      questionnaireValidityTimeInSeconds
      latitude
      longitude
      fieldgroupid
      vizsafeApiUrl
      createdAt
      updatedAt
      codeTranslations {
        items {
          tenant
          language
          questions {
            id
            question
          }
          disclaimer
          analyticResults {
            id
            alias
            answer
            proceed
          }
          adminGroup
          createdAt
          updatedAt
          tenantObj {
            name
            description
            prettyName
            url
            analyticConfiguration {
              tenant
              questionConfiguration {
                id
                weight
                weightTrigger
              }
              resultConfiguration {
                id
                threshold
                emailAddressesToAlert
              }
              adminGroup
              createdAt
              updatedAt
              tenantObj {
                name
                description
                prettyName
                url
                adminGroup
                allowedRoles
                tenantAdminGroup
                fullReadAccess
                resultMetricEmailList
                resultMetricEmailTime
                questionnaireValidityTimeInSeconds
                latitude
                longitude
                fieldgroupid
                vizsafeApiUrl
                createdAt
                updatedAt
              }
            }
            adminGroup
            allowedRoles
            tenantAdminGroup
            fullReadAccess
            resultMetricEmailList
            resultMetricEmailTime
            questionnaireValidityTimeInSeconds
            latitude
            longitude
            fieldgroupid
            vizsafeApiUrl
            createdAt
            updatedAt
            codeTranslations {
              items {
                tenant
                language
                disclaimer
                adminGroup
                createdAt
                updatedAt
              }
              nextToken
            }
            invites {
              id
              emailAddress
              tenantId
              tenantProvidedUserId
              tenantRole
              tenantAdminGroup
              verificationCode
              verificationExpiration
              validated
              username
              firstName
              lastName
              profileImage
              createdAt
              updatedAt
            }
          }
        }
        nextToken
      }
      invites {
        id
        emailAddress
        tenantId
        tenantProvidedUserId
        tenantRole
        tenantAdminGroup
        verificationCode
        verificationExpiration
        validated
        username
        firstName
        lastName
        profileImage
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteTenant = /* GraphQL */ `
  mutation DeleteTenant(
    $input: DeleteTenantInput!
    $condition: ModelTenantConditionInput
  ) {
    deleteTenant(input: $input, condition: $condition) {
      name
      description
      prettyName
      url
      analyticConfiguration {
        tenant
        questionConfiguration {
          id
          weight
          weightTrigger
        }
        resultConfiguration {
          id
          threshold
          emailAddressesToAlert
        }
        adminGroup
        createdAt
        updatedAt
        tenantObj {
          name
          description
          prettyName
          url
          analyticConfiguration {
            tenant
            questionConfiguration {
              id
              weight
              weightTrigger
            }
            resultConfiguration {
              id
              threshold
              emailAddressesToAlert
            }
            adminGroup
            createdAt
            updatedAt
            tenantObj {
              name
              description
              prettyName
              url
              analyticConfiguration {
                tenant
                adminGroup
                createdAt
                updatedAt
              }
              adminGroup
              allowedRoles
              tenantAdminGroup
              fullReadAccess
              resultMetricEmailList
              resultMetricEmailTime
              questionnaireValidityTimeInSeconds
              latitude
              longitude
              fieldgroupid
              vizsafeApiUrl
              createdAt
              updatedAt
              codeTranslations {
                nextToken
              }
              invites {
                id
                emailAddress
                tenantId
                tenantProvidedUserId
                tenantRole
                tenantAdminGroup
                verificationCode
                verificationExpiration
                validated
                username
                firstName
                lastName
                profileImage
                createdAt
                updatedAt
              }
            }
          }
          adminGroup
          allowedRoles
          tenantAdminGroup
          fullReadAccess
          resultMetricEmailList
          resultMetricEmailTime
          questionnaireValidityTimeInSeconds
          latitude
          longitude
          fieldgroupid
          vizsafeApiUrl
          createdAt
          updatedAt
          codeTranslations {
            items {
              tenant
              language
              questions {
                id
                question
              }
              disclaimer
              analyticResults {
                id
                alias
                answer
                proceed
              }
              adminGroup
              createdAt
              updatedAt
              tenantObj {
                name
                description
                prettyName
                url
                adminGroup
                allowedRoles
                tenantAdminGroup
                fullReadAccess
                resultMetricEmailList
                resultMetricEmailTime
                questionnaireValidityTimeInSeconds
                latitude
                longitude
                fieldgroupid
                vizsafeApiUrl
                createdAt
                updatedAt
              }
            }
            nextToken
          }
          invites {
            id
            emailAddress
            tenantId
            tenantProvidedUserId
            tenantRole
            tenantAdminGroup
            verificationCode
            verificationExpiration
            validated
            username
            firstName
            lastName
            profileImage
            createdAt
            updatedAt
          }
        }
      }
      adminGroup
      allowedRoles
      tenantAdminGroup
      fullReadAccess
      resultMetricEmailList
      resultMetricEmailTime
      questionnaireValidityTimeInSeconds
      latitude
      longitude
      fieldgroupid
      vizsafeApiUrl
      createdAt
      updatedAt
      codeTranslations {
        items {
          tenant
          language
          questions {
            id
            question
          }
          disclaimer
          analyticResults {
            id
            alias
            answer
            proceed
          }
          adminGroup
          createdAt
          updatedAt
          tenantObj {
            name
            description
            prettyName
            url
            analyticConfiguration {
              tenant
              questionConfiguration {
                id
                weight
                weightTrigger
              }
              resultConfiguration {
                id
                threshold
                emailAddressesToAlert
              }
              adminGroup
              createdAt
              updatedAt
              tenantObj {
                name
                description
                prettyName
                url
                adminGroup
                allowedRoles
                tenantAdminGroup
                fullReadAccess
                resultMetricEmailList
                resultMetricEmailTime
                questionnaireValidityTimeInSeconds
                latitude
                longitude
                fieldgroupid
                vizsafeApiUrl
                createdAt
                updatedAt
              }
            }
            adminGroup
            allowedRoles
            tenantAdminGroup
            fullReadAccess
            resultMetricEmailList
            resultMetricEmailTime
            questionnaireValidityTimeInSeconds
            latitude
            longitude
            fieldgroupid
            vizsafeApiUrl
            createdAt
            updatedAt
            codeTranslations {
              items {
                tenant
                language
                disclaimer
                adminGroup
                createdAt
                updatedAt
              }
              nextToken
            }
            invites {
              id
              emailAddress
              tenantId
              tenantProvidedUserId
              tenantRole
              tenantAdminGroup
              verificationCode
              verificationExpiration
              validated
              username
              firstName
              lastName
              profileImage
              createdAt
              updatedAt
            }
          }
        }
        nextToken
      }
      invites {
        id
        emailAddress
        tenantId
        tenantProvidedUserId
        tenantRole
        tenantAdminGroup
        verificationCode
        verificationExpiration
        validated
        username
        firstName
        lastName
        profileImage
        createdAt
        updatedAt
      }
    }
  }
`;
export const createEnrollerConfiguration = /* GraphQL */ `
  mutation CreateEnrollerConfiguration(
    $input: CreateEnrollerConfigurationInput!
    $condition: ModelEnrollerConfigurationConditionInput
  ) {
    createEnrollerConfiguration(input: $input, condition: $condition) {
      tenantId
      name
      description
      prettyName
      enrollerGroup
      createdAt
      updatedAt
    }
  }
`;
export const updateEnrollerConfiguration = /* GraphQL */ `
  mutation UpdateEnrollerConfiguration(
    $input: UpdateEnrollerConfigurationInput!
    $condition: ModelEnrollerConfigurationConditionInput
  ) {
    updateEnrollerConfiguration(input: $input, condition: $condition) {
      tenantId
      name
      description
      prettyName
      enrollerGroup
      createdAt
      updatedAt
    }
  }
`;
export const deleteEnrollerConfiguration = /* GraphQL */ `
  mutation DeleteEnrollerConfiguration(
    $input: DeleteEnrollerConfigurationInput!
    $condition: ModelEnrollerConfigurationConditionInput
  ) {
    deleteEnrollerConfiguration(input: $input, condition: $condition) {
      tenantId
      name
      description
      prettyName
      enrollerGroup
      createdAt
      updatedAt
    }
  }
`;
export const deleteMedicalTest = /* GraphQL */ `
  mutation DeleteMedicalTest(
    $input: DeleteMedicalTestInput!
    $condition: ModelMedicalTestConditionInput
  ) {
    deleteMedicalTest(input: $input, condition: $condition) {
      id
      tenantId
      enrollerGroup
      enrolleeIdentifier
      collectedBy
      dateOfCollection
      enrolleeFirstName
      enrolleeLastName
      enrolleeDateOfBirth
      enrolleeRace
      enrolleeGender
      enrolleeEthnicity
      enrolleeAddress
      enrolleeAddress1
      enrolleeCounty
      enrolleeCity
      enrolleeState
      enrolleeZipCode
      enrolleePhone
      enrolleeEmailAddress
      collectionMethod
      orderingProviderNPI
      orderingProviderName
      orderingProviderZipCode
      orderingProviderPhone
      orderingProviderAddress
      orderingProviderAddress1
      orderingProviderClia
      sampleCode
      sampleType
      sampleSource
      panelCode
      panelName
      status
      result
      resultDate
      consent
      orderDate
      updateTime
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      group
      tenantId
      enrollerConfigurationName
      placedBy
      orderDate
      updatedAt
      itemCode
      itemDescription
      quantity
      unitOfMeasure
      shipToAccount
      shipToName
      shipToAddress
      shipToState
      shipToCity
      shipToZip
      status
      updatedOn
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      group
      tenantId
      enrollerConfigurationName
      placedBy
      orderDate
      updatedAt
      itemCode
      itemDescription
      quantity
      unitOfMeasure
      shipToAccount
      shipToName
      shipToAddress
      shipToState
      shipToCity
      shipToZip
      status
      updatedOn
    }
  }
`;
export const createUserIdentificationKey = /* GraphQL */ `
  mutation CreateUserIdentificationKey($username: String) {
    createUserIdentificationKey(username: $username) {
      owner
      identificationKey
      qrcode
      createdAt
      updatedAt
    }
  }
`;
export const createHealthCard = /* GraphQL */ `
  mutation CreateHealthCard(
    $userId: ID
    $tenantId: ID
    $tenantProvidedUserId: ID
  ) {
    createHealthCard(
      userId: $userId
      tenantId: $tenantId
      tenantProvidedUserId: $tenantProvidedUserId
    ) {
      owner
      medicalTestQRCode
      createdAt
      updatedAt
    }
  }
`;
export const observerUserLookup = /* GraphQL */ `
  mutation ObserverUserLookup($emailAddress: String) {
    observerUserLookup(emailAddress: $emailAddress) {
      items {
        username
        email
      }
    }
  }
`;
export const deleteUserIdentificationKey = /* GraphQL */ `
  mutation DeleteUserIdentificationKey(
    $input: DeleteUserIdentificationKeyInput!
    $condition: ModelUserIdentificationKeyConditionInput
  ) {
    deleteUserIdentificationKey(input: $input, condition: $condition) {
      owner
      identificationKey
      qrcode
      createdAt
      updatedAt
    }
  }
`;
export const createUserTenant = /* GraphQL */ `
  mutation CreateUserTenant(
    $input: CreateUserTenantInput!
    $condition: ModelUserTenantConditionInput
  ) {
    createUserTenant(input: $input, condition: $condition) {
      userId
      tenantId
      tenantProvidedUserId
      tenantRole
      tenantAdminGroup
      firstName
      lastName
      type
      createdAt
      updatedAt
      tenantObj {
        name
        description
        prettyName
        url
        analyticConfiguration {
          tenant
          questionConfiguration {
            id
            weight
            weightTrigger
          }
          resultConfiguration {
            id
            threshold
            emailAddressesToAlert
          }
          adminGroup
          createdAt
          updatedAt
          tenantObj {
            name
            description
            prettyName
            url
            analyticConfiguration {
              tenant
              questionConfiguration {
                id
                weight
                weightTrigger
              }
              resultConfiguration {
                id
                threshold
                emailAddressesToAlert
              }
              adminGroup
              createdAt
              updatedAt
              tenantObj {
                name
                description
                prettyName
                url
                adminGroup
                allowedRoles
                tenantAdminGroup
                fullReadAccess
                resultMetricEmailList
                resultMetricEmailTime
                questionnaireValidityTimeInSeconds
                latitude
                longitude
                fieldgroupid
                vizsafeApiUrl
                createdAt
                updatedAt
              }
            }
            adminGroup
            allowedRoles
            tenantAdminGroup
            fullReadAccess
            resultMetricEmailList
            resultMetricEmailTime
            questionnaireValidityTimeInSeconds
            latitude
            longitude
            fieldgroupid
            vizsafeApiUrl
            createdAt
            updatedAt
            codeTranslations {
              items {
                tenant
                language
                disclaimer
                adminGroup
                createdAt
                updatedAt
              }
              nextToken
            }
            invites {
              id
              emailAddress
              tenantId
              tenantProvidedUserId
              tenantRole
              tenantAdminGroup
              verificationCode
              verificationExpiration
              validated
              username
              firstName
              lastName
              profileImage
              createdAt
              updatedAt
            }
          }
        }
        adminGroup
        allowedRoles
        tenantAdminGroup
        fullReadAccess
        resultMetricEmailList
        resultMetricEmailTime
        questionnaireValidityTimeInSeconds
        latitude
        longitude
        fieldgroupid
        vizsafeApiUrl
        createdAt
        updatedAt
        codeTranslations {
          items {
            tenant
            language
            questions {
              id
              question
            }
            disclaimer
            analyticResults {
              id
              alias
              answer
              proceed
            }
            adminGroup
            createdAt
            updatedAt
            tenantObj {
              name
              description
              prettyName
              url
              analyticConfiguration {
                tenant
                adminGroup
                createdAt
                updatedAt
              }
              adminGroup
              allowedRoles
              tenantAdminGroup
              fullReadAccess
              resultMetricEmailList
              resultMetricEmailTime
              questionnaireValidityTimeInSeconds
              latitude
              longitude
              fieldgroupid
              vizsafeApiUrl
              createdAt
              updatedAt
              codeTranslations {
                nextToken
              }
              invites {
                id
                emailAddress
                tenantId
                tenantProvidedUserId
                tenantRole
                tenantAdminGroup
                verificationCode
                verificationExpiration
                validated
                username
                firstName
                lastName
                profileImage
                createdAt
                updatedAt
              }
            }
          }
          nextToken
        }
        invites {
          id
          emailAddress
          tenantId
          tenantProvidedUserId
          tenantRole
          tenantAdminGroup
          verificationCode
          verificationExpiration
          validated
          username
          firstName
          lastName
          profileImage
          createdAt
          updatedAt
        }
      }
      emailVerified
      subs {
        items {
          userId
          tenantId
          subscribedTo
          resultCode
          tenantAdminGroup
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateUserTenant = /* GraphQL */ `
  mutation UpdateUserTenant(
    $input: UpdateUserTenantInput!
    $condition: ModelUserTenantConditionInput
  ) {
    updateUserTenant(input: $input, condition: $condition) {
      userId
      tenantId
      tenantProvidedUserId
      tenantRole
      tenantAdminGroup
      firstName
      lastName
      type
      createdAt
      updatedAt
      tenantObj {
        name
        description
        prettyName
        url
        analyticConfiguration {
          tenant
          questionConfiguration {
            id
            weight
            weightTrigger
          }
          resultConfiguration {
            id
            threshold
            emailAddressesToAlert
          }
          adminGroup
          createdAt
          updatedAt
          tenantObj {
            name
            description
            prettyName
            url
            analyticConfiguration {
              tenant
              questionConfiguration {
                id
                weight
                weightTrigger
              }
              resultConfiguration {
                id
                threshold
                emailAddressesToAlert
              }
              adminGroup
              createdAt
              updatedAt
              tenantObj {
                name
                description
                prettyName
                url
                adminGroup
                allowedRoles
                tenantAdminGroup
                fullReadAccess
                resultMetricEmailList
                resultMetricEmailTime
                questionnaireValidityTimeInSeconds
                latitude
                longitude
                fieldgroupid
                vizsafeApiUrl
                createdAt
                updatedAt
              }
            }
            adminGroup
            allowedRoles
            tenantAdminGroup
            fullReadAccess
            resultMetricEmailList
            resultMetricEmailTime
            questionnaireValidityTimeInSeconds
            latitude
            longitude
            fieldgroupid
            vizsafeApiUrl
            createdAt
            updatedAt
            codeTranslations {
              items {
                tenant
                language
                disclaimer
                adminGroup
                createdAt
                updatedAt
              }
              nextToken
            }
            invites {
              id
              emailAddress
              tenantId
              tenantProvidedUserId
              tenantRole
              tenantAdminGroup
              verificationCode
              verificationExpiration
              validated
              username
              firstName
              lastName
              profileImage
              createdAt
              updatedAt
            }
          }
        }
        adminGroup
        allowedRoles
        tenantAdminGroup
        fullReadAccess
        resultMetricEmailList
        resultMetricEmailTime
        questionnaireValidityTimeInSeconds
        latitude
        longitude
        fieldgroupid
        vizsafeApiUrl
        createdAt
        updatedAt
        codeTranslations {
          items {
            tenant
            language
            questions {
              id
              question
            }
            disclaimer
            analyticResults {
              id
              alias
              answer
              proceed
            }
            adminGroup
            createdAt
            updatedAt
            tenantObj {
              name
              description
              prettyName
              url
              analyticConfiguration {
                tenant
                adminGroup
                createdAt
                updatedAt
              }
              adminGroup
              allowedRoles
              tenantAdminGroup
              fullReadAccess
              resultMetricEmailList
              resultMetricEmailTime
              questionnaireValidityTimeInSeconds
              latitude
              longitude
              fieldgroupid
              vizsafeApiUrl
              createdAt
              updatedAt
              codeTranslations {
                nextToken
              }
              invites {
                id
                emailAddress
                tenantId
                tenantProvidedUserId
                tenantRole
                tenantAdminGroup
                verificationCode
                verificationExpiration
                validated
                username
                firstName
                lastName
                profileImage
                createdAt
                updatedAt
              }
            }
          }
          nextToken
        }
        invites {
          id
          emailAddress
          tenantId
          tenantProvidedUserId
          tenantRole
          tenantAdminGroup
          verificationCode
          verificationExpiration
          validated
          username
          firstName
          lastName
          profileImage
          createdAt
          updatedAt
        }
      }
      emailVerified
      subs {
        items {
          userId
          tenantId
          subscribedTo
          resultCode
          tenantAdminGroup
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteUserTenant = /* GraphQL */ `
  mutation DeleteUserTenant(
    $input: DeleteUserTenantInput!
    $condition: ModelUserTenantConditionInput
  ) {
    deleteUserTenant(input: $input, condition: $condition) {
      userId
      tenantId
      tenantProvidedUserId
      tenantRole
      tenantAdminGroup
      firstName
      lastName
      type
      createdAt
      updatedAt
      tenantObj {
        name
        description
        prettyName
        url
        analyticConfiguration {
          tenant
          questionConfiguration {
            id
            weight
            weightTrigger
          }
          resultConfiguration {
            id
            threshold
            emailAddressesToAlert
          }
          adminGroup
          createdAt
          updatedAt
          tenantObj {
            name
            description
            prettyName
            url
            analyticConfiguration {
              tenant
              questionConfiguration {
                id
                weight
                weightTrigger
              }
              resultConfiguration {
                id
                threshold
                emailAddressesToAlert
              }
              adminGroup
              createdAt
              updatedAt
              tenantObj {
                name
                description
                prettyName
                url
                adminGroup
                allowedRoles
                tenantAdminGroup
                fullReadAccess
                resultMetricEmailList
                resultMetricEmailTime
                questionnaireValidityTimeInSeconds
                latitude
                longitude
                fieldgroupid
                vizsafeApiUrl
                createdAt
                updatedAt
              }
            }
            adminGroup
            allowedRoles
            tenantAdminGroup
            fullReadAccess
            resultMetricEmailList
            resultMetricEmailTime
            questionnaireValidityTimeInSeconds
            latitude
            longitude
            fieldgroupid
            vizsafeApiUrl
            createdAt
            updatedAt
            codeTranslations {
              items {
                tenant
                language
                disclaimer
                adminGroup
                createdAt
                updatedAt
              }
              nextToken
            }
            invites {
              id
              emailAddress
              tenantId
              tenantProvidedUserId
              tenantRole
              tenantAdminGroup
              verificationCode
              verificationExpiration
              validated
              username
              firstName
              lastName
              profileImage
              createdAt
              updatedAt
            }
          }
        }
        adminGroup
        allowedRoles
        tenantAdminGroup
        fullReadAccess
        resultMetricEmailList
        resultMetricEmailTime
        questionnaireValidityTimeInSeconds
        latitude
        longitude
        fieldgroupid
        vizsafeApiUrl
        createdAt
        updatedAt
        codeTranslations {
          items {
            tenant
            language
            questions {
              id
              question
            }
            disclaimer
            analyticResults {
              id
              alias
              answer
              proceed
            }
            adminGroup
            createdAt
            updatedAt
            tenantObj {
              name
              description
              prettyName
              url
              analyticConfiguration {
                tenant
                adminGroup
                createdAt
                updatedAt
              }
              adminGroup
              allowedRoles
              tenantAdminGroup
              fullReadAccess
              resultMetricEmailList
              resultMetricEmailTime
              questionnaireValidityTimeInSeconds
              latitude
              longitude
              fieldgroupid
              vizsafeApiUrl
              createdAt
              updatedAt
              codeTranslations {
                nextToken
              }
              invites {
                id
                emailAddress
                tenantId
                tenantProvidedUserId
                tenantRole
                tenantAdminGroup
                verificationCode
                verificationExpiration
                validated
                username
                firstName
                lastName
                profileImage
                createdAt
                updatedAt
              }
            }
          }
          nextToken
        }
        invites {
          id
          emailAddress
          tenantId
          tenantProvidedUserId
          tenantRole
          tenantAdminGroup
          verificationCode
          verificationExpiration
          validated
          username
          firstName
          lastName
          profileImage
          createdAt
          updatedAt
        }
      }
      emailVerified
      subs {
        items {
          userId
          tenantId
          subscribedTo
          resultCode
          tenantAdminGroup
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createMedicalTest = /* GraphQL */ `
  mutation CreateMedicalTest(
    $input: CreateMedicalTestInput!
    $condition: ModelMedicalTestConditionInput
  ) {
    createMedicalTest(input: $input, condition: $condition) {
      id
      tenantId
      enrollerGroup
      enrolleeIdentifier
      collectedBy
      dateOfCollection
      enrolleeFirstName
      enrolleeLastName
      enrolleeDateOfBirth
      enrolleeRace
      enrolleeGender
      enrolleeEthnicity
      enrolleeAddress
      enrolleeAddress1
      enrolleeCounty
      enrolleeCity
      enrolleeState
      enrolleeZipCode
      enrolleePhone
      enrolleeEmailAddress
      collectionMethod
      orderingProviderNPI
      orderingProviderName
      orderingProviderZipCode
      orderingProviderPhone
      orderingProviderAddress
      orderingProviderAddress1
      orderingProviderClia
      sampleCode
      sampleType
      sampleSource
      panelCode
      panelName
      status
      result
      resultDate
      consent
      orderDate
      updateTime
    }
  }
`;
export const updateMedicalTest = /* GraphQL */ `
  mutation UpdateMedicalTest(
    $input: UpdateMedicalTestInput!
    $condition: ModelMedicalTestConditionInput
  ) {
    updateMedicalTest(input: $input, condition: $condition) {
      id
      tenantId
      enrollerGroup
      enrolleeIdentifier
      collectedBy
      dateOfCollection
      enrolleeFirstName
      enrolleeLastName
      enrolleeDateOfBirth
      enrolleeRace
      enrolleeGender
      enrolleeEthnicity
      enrolleeAddress
      enrolleeAddress1
      enrolleeCounty
      enrolleeCity
      enrolleeState
      enrolleeZipCode
      enrolleePhone
      enrolleeEmailAddress
      collectionMethod
      orderingProviderNPI
      orderingProviderName
      orderingProviderZipCode
      orderingProviderPhone
      orderingProviderAddress
      orderingProviderAddress1
      orderingProviderClia
      sampleCode
      sampleType
      sampleSource
      panelCode
      panelName
      status
      result
      resultDate
      consent
      orderDate
      updateTime
    }
  }
`;
export const createUserSubscription = /* GraphQL */ `
  mutation CreateUserSubscription(
    $input: CreateUserSubscriptionInput!
    $condition: ModelUserSubscriptionConditionInput
  ) {
    createUserSubscription(input: $input, condition: $condition) {
      userId
      tenantId
      subscribedTo
      resultCode
      tenantAdminGroup
      createdAt
      updatedAt
    }
  }
`;
export const updateUserSubscription = /* GraphQL */ `
  mutation UpdateUserSubscription(
    $input: UpdateUserSubscriptionInput!
    $condition: ModelUserSubscriptionConditionInput
  ) {
    updateUserSubscription(input: $input, condition: $condition) {
      userId
      tenantId
      subscribedTo
      resultCode
      tenantAdminGroup
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserSubscription = /* GraphQL */ `
  mutation DeleteUserSubscription(
    $input: DeleteUserSubscriptionInput!
    $condition: ModelUserSubscriptionConditionInput
  ) {
    deleteUserSubscription(input: $input, condition: $condition) {
      userId
      tenantId
      subscribedTo
      resultCode
      tenantAdminGroup
      createdAt
      updatedAt
    }
  }
`;
export const createTenantInvite = /* GraphQL */ `
  mutation CreateTenantInvite(
    $input: CreateTenantInviteInput!
    $condition: ModelTenantInviteConditionInput
  ) {
    createTenantInvite(input: $input, condition: $condition) {
      id
      emailAddress
      tenantId
      tenantProvidedUserId
      tenantRole
      tenantAdminGroup
      verificationCode
      verificationExpiration
      validated
      username
      firstName
      lastName
      profileImage
      createdAt
      updatedAt
    }
  }
`;
export const updateTenantInvite = /* GraphQL */ `
  mutation UpdateTenantInvite(
    $input: UpdateTenantInviteInput!
    $condition: ModelTenantInviteConditionInput
  ) {
    updateTenantInvite(input: $input, condition: $condition) {
      id
      emailAddress
      tenantId
      tenantProvidedUserId
      tenantRole
      tenantAdminGroup
      verificationCode
      verificationExpiration
      validated
      username
      firstName
      lastName
      profileImage
      createdAt
      updatedAt
    }
  }
`;
export const deleteTenantInvite = /* GraphQL */ `
  mutation DeleteTenantInvite(
    $input: DeleteTenantInviteInput!
    $condition: ModelTenantInviteConditionInput
  ) {
    deleteTenantInvite(input: $input, condition: $condition) {
      id
      emailAddress
      tenantId
      tenantProvidedUserId
      tenantRole
      tenantAdminGroup
      verificationCode
      verificationExpiration
      validated
      username
      firstName
      lastName
      profileImage
      createdAt
      updatedAt
    }
  }
`;
export const createUserHealthCard = /* GraphQL */ `
  mutation CreateUserHealthCard(
    $input: CreateUserHealthCardInput!
    $condition: ModelUserHealthCardConditionInput
  ) {
    createUserHealthCard(input: $input, condition: $condition) {
      owner
      medicalTestQRCode
      createdAt
      updatedAt
    }
  }
`;
export const updateUserHealthCard = /* GraphQL */ `
  mutation UpdateUserHealthCard(
    $input: UpdateUserHealthCardInput!
    $condition: ModelUserHealthCardConditionInput
  ) {
    updateUserHealthCard(input: $input, condition: $condition) {
      owner
      medicalTestQRCode
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserHealthCard = /* GraphQL */ `
  mutation DeleteUserHealthCard(
    $input: DeleteUserHealthCardInput!
    $condition: ModelUserHealthCardConditionInput
  ) {
    deleteUserHealthCard(input: $input, condition: $condition) {
      owner
      medicalTestQRCode
      createdAt
      updatedAt
    }
  }
`;
export const createObservation = /* GraphQL */ `
  mutation CreateObservation(
    $input: CreateObservationInput!
    $condition: ModelObservationConditionInput
  ) {
    createObservation(input: $input, condition: $condition) {
      id
      userId
      tenant
      tenantManagerGroup
      tenantProvidedUserId
      firstName
      lastName
      email
      result
      category
      productName
      createdAt
      notes
      updatedAt
    }
  }
`;
export const updateObservation = /* GraphQL */ `
  mutation UpdateObservation(
    $input: UpdateObservationInput!
    $condition: ModelObservationConditionInput
  ) {
    updateObservation(input: $input, condition: $condition) {
      id
      userId
      tenant
      tenantManagerGroup
      tenantProvidedUserId
      firstName
      lastName
      email
      result
      category
      productName
      createdAt
      notes
      updatedAt
    }
  }
`;
export const deleteObservation = /* GraphQL */ `
  mutation DeleteObservation(
    $input: DeleteObservationInput!
    $condition: ModelObservationConditionInput
  ) {
    deleteObservation(input: $input, condition: $condition) {
      id
      userId
      tenant
      tenantManagerGroup
      tenantProvidedUserId
      firstName
      lastName
      email
      result
      category
      productName
      createdAt
      notes
      updatedAt
    }
  }
`;
